<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <h2 class="display-1 ml-4">{{ title }}</h2>
            <v-spacer />
            <v-btn text color="accent" @click="save(1)">Salva</v-btn>
            <v-btn text color="accent" @click="save(2)">Salva e continua</v-btn>
            <v-btn text color="accent" @click="save(3)">Salva e aggiungi</v-btn>
          </v-card-title>
          <v-card-text>
            <v-container fluid>
              <v-row>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-text-field
                          label="Nome*"
                          rounded filled
                          v-model="editingItem.name"
                          :error="nameError"
                          :error-messages="requiredErrorMessages(nameError)"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-select
                          label="Tipologia*"
                          rounded filled
                          v-model="editingItem.media"
                          :items="mediatypes"
                          :error="mediaError"

                          @change="file = null"
                          :error-messages="requiredErrorMessages(mediaError)"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-file-input v-model="file" chips rounded filled show-size
                                :accept="acceptTypes" label="File"
                                :hint="mediaHint"
                                persistent-hint
                                placeholder="Clicca qui per scegliere il file"></v-file-input>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-text-field
                          label="Url"
                          rounded filled
                          persistent-hint
                          hint="In alternativa al file upload, accertati che le dimensioni siano corrette: icona 512x512, copertina 1280x720, banner 960x150"
                          v-model="editingItem.url"
                          :disabled="file != null"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn text color="accent" @click="save(1)">Salva</v-btn>
            <v-btn text color="accent" @click="save(2)">Salva e continua</v-btn>
            <v-btn text color="accent" @click="save(3)">Salva e aggiungi</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import utils from '../utils';
  import { mapGetters, mapActions } from 'vuex';
  import config from "../config";
  import moment from 'moment'
  export default {
    name: "GenreForm",
    data () {
      return {
        file: null,
        editingItem : {
          name : '',
          media : 'icon',
          url : '',
        },
        mediatypes : config.mediatypes,
        nameError : false,
        mediaError : false,
      }
    },
    computed : {
      ...mapGetters(['token', 'profile', 'is_root']),
      mediaHint() {
        switch (this.editingItem.media) {
          case "icon":
            return "verrà ridimensionato e tagliato a 512x512"
          case "cover":
            return "verrà ridimensionato e tagliato a 1280x720"
          case "banner":
            return "verrà ridimensionato e tagliato a 960x150"
          default:
              return ""
        }
      },
      acceptTypes() {
        switch (this.editingItem.media) {
          case "audio":
            return ".mp3";
          case "video":
            return ".mp4";
          case "icon":
          case "cover":
          default:
            return ".jpg, .jpeg, .png";
        }
      },
      updating() {
        return !!this.editingItem._id
      },
      title() {
        return this.$route.params.id === 'new' ? 'Nuovo media' : 'Modifica media';
      },
      loading : {
        get() { return this.$store.state.loading; },
        set(val) { this.$store.state.loading = val; }
      }
    },
    methods : {
      ...mapActions(['sendSuccess', 'sendError', 'requestConfirm', 'logout', 'load', 'delete','get', 'update','upload', 'insert']),
      requiredErrorMessages(err) {
        if(err) return ["Campo richiesto"];
        return []
      },
      loadData() {
        this.loading = true;
        let id = this.$route.params.id;
        this.get({collection : 'media', id}).then((reply)=>{
          this.loading = false;
          if(reply.data) {
            this.editingItem = reply.data;
          }
        }).catch((err) => {
          this.loading = false;
          let message = err.message ? err.message : 'errore al caricamento dei dati del media';
          this.sendError({message});
          this.$router.go(-1);
          if(err.statusCode === 401) {
            this.logout();
          }
        })
      },
      clearErrors() {
        this.nameError = false;
        this.mediaError = false;
      },
      uploadMedia() {
        return new Promise((resolve, reject) => {
          if(!this.editingItem._id || !this.file) {
            reject("Non hai selezionato il file da inviare!");
            return
          }
          let formData = new FormData();
          formData.append("media", this.file);
          this.upload({ where : 'media/' + this.editingItem._id + '/upload', formData }).then(resolve).catch(reject);
        });
      },
      save( afterAction ) {
        this.loading = true;
        this.clearErrors();
        let updating = this.updating;

        if(!this.editingItem.name) {
          this.nameError = true;
          return;
        }

        if(!this.editingItem.media) {
          this.nameError = true;
          return;
        }

        if(!this.editingItem.url && !this.file) {
          this.sendError({message: "Devi inserire un file o una url verso un file per poter salvare il media"});
          return;
        }

        let saveFn = updating ? this.update : this.insert;
        saveFn({collection:'media', data : this.editingItem}).then((reply)=>{

          if(!reply.data) {
            if(reply.message) {
              this.loading = false;
              this.sendError({message: reply.message});
            } else {
              this.sendError({message: 'Oops! c\'è stato un errore'});
            }
            return
          }

          this.editingItem = reply.data;
          let onComplete = () => {
            this.loading = false;
            this.sendSuccess({ message : "Media salvato"});
            switch (afterAction) {
              case 1:
                //go back
                this.$router.replace("/media");
                break;
              case 2:
                //stay
                if(this.$route.params.id === 'new') {
                  this.$router.replace("/media/"+ reply.data._id);
                } else {
                  this.editingItem = reply.data;
                }
                break;
              case 3:
                // new
                if(this.$route.params.id === 'new') {
                  this.editingItem = {
                    name : { it : ''},
                    abbrev : { it : ''},
                  };
                } else {
                  this.$router.replace("/media/new");
                }
                break;
            }
          };

          if(this.file) {
            this.uploadMedia().then(onComplete).catch((err) => {
              this.loading = false;
              this.sendError({ message : err });
            });
          } else {
            onComplete();
          }
        }).catch((err)=>{
          if(config.debug) console.log("Save media error:", err);
          let message = err.message ? err.message : "errore al salvataggio del media";
          this.sendError({ message });
          if(err.statusCode === 401) {
            this.$router.replace("/");
            this.logout();
          }
        })
      }
    },
    mounted() {
      if(this.$route.params.id !== 'new') {
        this.loadData()
      }
    }
  }
</script>

<style scoped></style>
