<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <h2 class="display-1 ml-4">{{ title }}</h2>
            <v-spacer />
            <v-btn text color="accent" @click="save(1)">Salva</v-btn>
            <v-btn text color="accent" @click="save(2)">Salva e continua</v-btn>
            <v-btn text color="accent" @click="save(3)">Salva e aggiungi</v-btn>
            <v-btn text color="accent" @click="save(4)">Salva e aggiungi prodotti</v-btn>
          </v-card-title>
          <v-card-text>
            <v-container fluid>
              <v-row>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-text-field
                      label="Ordinamento*"
                      rounded filled
                      type="number"
                      step="1"
                      min="0"
                      v-model="editingItem.order"
                      :error="orderError"
                      :error-messages="requiredErrorMessages(orderError)"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-text-field
                          label="Titolo*"
                          rounded filled
                          v-model="editingItem.title.it"
                          :error="titleError"
                          :error-messages="requiredErrorMessages(titleError)"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-select
                          label="Categoria*"
                          rounded filled
                          v-model="editingItem.category"
                          :items="categoryOptions"
                          :error="categoryError"
                          :error-messages="requiredErrorMessages(categoryError)"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-select
                          label="Genere*"
                          rounded filled
                          v-model="editingItem.genre"
                          :items="genreOptions"
                          :error="genreError"
                          :error-messages="requiredErrorMessages(genreError)"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-select
                          label="Difficoltà*"
                          rounded filled
                          v-model="editingItem.difficulty"
                          :items="difficultyOptions"
                          :error="difficultyError"
                          :error-messages="requiredErrorMessages(difficultyError)"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-autocomplete
                          label="Immagine copertina*"
                          rounded filled
                          v-model="editingItem.cover"
                          :items="coverOptions"
                          :error="coverError"
                          :error-messages="requiredErrorMessages(coverError)"
                          prepend-icon="mdi-cloud-upload"
                          @click:prepend="openUploadDialog('cover')"
                  ></v-autocomplete>
                  <v-img v-if="editingItem.cover" :src="selectedCoverUrl" max-height="120" contain style="background: #eee"/>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-autocomplete
                          label="Immagine icona*"
                          rounded filled
                          v-model="editingItem.icon"
                          :items="iconOptions"
                          :error="iconError"
                          :error-messages="requiredErrorMessages(iconError)"
                          prepend-icon="mdi-cloud-upload"
                          @click:prepend="openUploadDialog('icon')"
                  ></v-autocomplete>
                  <v-img v-if="editingItem.icon" :src="selectedIconUrl" max-height="120" contain style="background: #eee"/>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-textarea
                          label="Descrizione*"
                          rounded filled
                          v-model="editingItem.description.it"
                          :error="descError"
                          :error-messages="requiredErrorMessages(descError)"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-switch
                          label="Pubblicato"
                          rounded filled
                          v-model="editingItem.pub"
                  ></v-switch>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn text color="accent" @click="save(1)">Salva</v-btn>
            <v-btn text color="accent" @click="save(2)">Salva e continua</v-btn>
            <v-btn text color="accent" @click="save(3)">Salva e aggiungi</v-btn>
            <v-btn text color="accent" @click="save(4)">Salva e aggiungi prodotti</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="uploadDialog" persistent max-width="500">
      <v-card>
        <v-card-title class="headline">Carica immagine</v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col cols="12" >
                <v-text-field
                        label="Nome*"
                        rounded filled
                        v-model="uploadMedia.name"
                        :error="mediaNameError"
                        :error-messages="requiredErrorMessages(mediaNameError)"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-file-input v-model="file" chips rounded filled show-size
                              accept=".jpg, .jpeg, .png" label="File"
                              placeholder="Clicca qui per scegliere il file"></v-file-input>
              </v-col>
              <v-col cols="12" >
                <v-text-field
                        label="Url"
                        rounded filled
                        persistent-hint
                        hint="In alternativa al file upload"
                        v-model="uploadMedia.url"
                        :disabled="file != null"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text :disabled="uploading" @click="uploadDialog = false">Annulla</v-btn>
          <v-btn color="error" :disabled="uploading" :loading="uploading" text @click="beginUpload()">Inserisci</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import utils from '../utils';
  import { mapGetters, mapActions } from 'vuex';
  import config from "../config";
  import moment from 'moment'
  export default {
    name: "KitForm",
    data () {
      return {
        editingItem : {
          title : { it : ''},
          description : { it : ''},
          category : null,
          genre : null,
          difficulty : null,
          cover : null,
          icon : null,
          order : 100,
          pub : false,
        },
        file : null,
        uploading : false,
        uploadDialog : false,
        uploadMedia: {
          name : '',
          media : 'icon',
          url : '',
        },
        mediaNameError : false,
        orderError : false,
        titleError : false,
        descError : false,
        categoryError : false,
        genreError : false,
        difficultyError : false,
        coverError : false,
        iconError : false,
      }
    },
    computed : {
      ...mapGetters(['token', 'profile', 'is_root','genre','categories','media','difficulties']),
      updating() {
        return !!this.editingItem._id
      },
      title() {
        return this.$route.params.id === 'new' ? 'Nuovo pacchetto' : 'Modifica pacchetto';
      },
      loading : {
        get() { return this.$store.state.loading; },
        set(val) { this.$store.state.loading = val; }
      },
      categoryOptions() {
        return this.categories.map((item)=>{
          return { text : item.name.it, value: item._id }
        })
      },
      genreOptions() {
        return this.genre.map((item)=>{
          return { text : item.name.it, value: item._id }
        })
      },
      difficultyOptions() {
        return this.difficulties.map((item)=>{
          return { text : item.name.it, value: item._id }
        })
      },
      coverOptions() {
        return this.media.filter((item) => {
          return item.media === 'cover'
        }).map((item)=>{
          return { text : item.name, value: item._id }
        })
      },
      iconOptions() {
        return this.media.filter((item) => {
          return item.media === 'icon'
        }).map((item)=>{
          return { text : item.name, value: item._id }
        })
      },
      selectedCoverUrl() {
        let media = this.getMedia(this.editingItem.cover);
        if(!media) return "";
        return media.url || "";
      },
      selectedIconUrl() {
        let media = this.getMedia(this.editingItem.icon);
        if(!media) return "";
        return media.url || "";
      }
    },
    methods : {
      ...mapActions(['sendSuccess', 'sendError', 'requestConfirm', 'logout', 'load', 'delete','get', 'update', 'insert','refreshCollection','upload']),
      openUploadDialog( mediatype ) {
        this.uploadMedia.media = mediatype;
        this.uploadDialog = true;
        this.uploading = false;
      },
      beginUpload() {
        this.mediaNameError = false;
        if(!this.uploadMedia.name) {
          this.mediaNameError = true;
          return
        }
        this.uploading = true;
        this.insert({collection:"media", data: this.uploadMedia}).then((reply)=>{
          if(reply.data) {
            if(reply.data.media === 'icon') this.editingItem.icon = reply.data._id;
            if(reply.data.media === 'cover') this.editingItem.cover = reply.data._id;
            if(this.file) {
              let formData = new FormData();
              formData.append("media", this.file);
              this.upload({ where : 'media/' + reply.data._id + '/upload', formData }).then(() => {
                this.refreshCollection({collection: 'media'});
                this.uploading = false;
                this.uploadDialog = false;
                this.file = null;
                this.uploadMedia.name = '';
                this.uploadMedia.url = '';
              }).catch(onError);
              return
            }
            this.refreshCollection({collection: 'media'});
            this.sendSuccess({message: "Media inserito."})
          } else {
            this.sendError({message : 'errore all\'inserimento del media'});
          }
          this.uploading = false;
          this.uploadDialog = false;
        }).catch(onError);

        let onError = (err) => {
          this.uploading = false;
          let message = err.message ? err.message : 'errore all\'inserimento del media';
          this.sendError({message});
          if(err.statusCode === 401) {
            this.logout();
          }
        }
      },
      requiredErrorMessages(err) {
        if(err) return ["Campo richiesto"];
        return []
      },
      getMedia( id ) {
        for(let i = 0; i < this.media.length; i++) {
          if(this.media[i]._id === id) {
            return this.media[i];
          }
        }
        return null
      },
      loadData() {
        this.loading = true;
        let id = this.$route.params.id;
        this.get({collection : 'kits', id}).then((reply)=>{
          this.loading = false;
          if(reply.data) {
            this.editingItem = reply.data;
          }
        }).catch((err) => {
          this.loading = false;
          let message = err.message ? err.message : 'errore al caricamento dei dati del pacchetto';
          this.sendError({message});
          this.$router.go(-1);
          if(err.statusCode === 401) {
            this.logout();
          }
        })
      },
      clearErrors() {
        this.titleError = false;
        this.descError = false;
        this.categoryError = false;
        this.genreError = false;
        this.difficultyError = false;
        this.coverError = false;
        this.iconError = false;
      },
      save( afterAction ) {
        this.clearErrors();
        let updating = this.updating;

        this.editingItem.order = utils.toInt(this.editingItem.order, -1)



        if(this.editingItem.order < 0) {
          this.orderError = true;
          return;
        }
        if(!this.editingItem.title.it) {
          this.titleError = true;
          return;
        }
        if(!this.editingItem.description.it) {
          this.descError = true;
          return;
        }
        if(!this.editingItem.category) {
          this.categoryError = true;
          return;
        }
        if(!this.editingItem.genre) {
          this.genreError = true;
          return;
        }
        if(!this.editingItem.difficulty) {
          this.difficultyError = true;
          return;
        }
        if(!this.editingItem.cover) {
          this.coverError = true;
          return;
        }
        if(!this.editingItem.icon) {
          this.iconError = true;
          return;
        }

        let saveFn = updating ? this.update : this.insert;
        saveFn({collection:'kits', data : this.editingItem}).then((reply)=>{
          this.sendSuccess({ message : "Pacchetto salvato"});
          switch (afterAction) {
            case 1:
              //go back
              this.$router.replace("/kits");
              break;
            case 2:
              //stay
              if(this.$route.params.id === 'new') {
                this.$router.replace("/kits/"+ reply.data._id);
              } else {
                this.editingItem = reply.data;
              }
              break;
            case 3:
              // new
              if(this.$route.params.id === 'new') {
                this.editingItem = {
                  title : { it : ''},
                  description : { it : ''},
                  category : null,
                  genre : null,
                  difficulty : null,
                  cover : null,
                  icon : null,
                  pub : false,
                };
              } else {
                this.$router.replace("/kits/new");
              }
              break;
            case 4:
              // new
              this.$router.replace({ name : 'kit products', params : { id : reply.data._id }});
              break;
          }
        }).catch((err)=>{
          if(config.debug) console.log("Save kit error:", err);
          let message = err.message ? err.message : "errore al salvataggio del pacchetto";
          this.sendError({ message });
          if(err.statusCode === 401) {
            this.$router.replace("/");
            this.logout();
          }
        })
      }
    },
    mounted() {
      this.refreshCollection({collection:"categories"});
      this.refreshCollection({collection:"genre"});
      this.refreshCollection({collection:"difficulties"});
      this.refreshCollection({collection:"media"});
      if(this.$route.params.id !== 'new') {
        this.loadData()
      }
    }
  }
</script>

<style scoped></style>
