<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <h2 class="display-1 ml-4">{{ title }}</h2>
            <v-spacer />
            <v-btn text color="accent" @click="save(1)">Salva</v-btn>
            <v-btn text color="accent" @click="save(2)">Salva e continua</v-btn>
            <v-btn text color="accent" @click="save(3)">Salva e aggiungi</v-btn>
          </v-card-title>
          <v-card-text>
            <v-container fluid>
              <v-form ref="form">
                <v-row>
                  <v-col cols="12" md="6" lg="4" xl="3">
                    <v-text-field
                        label="Nome*"
                        rounded filled
                        v-model="editingItem.name"
                        :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" lg="4" xl="3">
                    <v-select
                        label="Tipo calendario*"
                        rounded filled
                        v-model="editingItem.seats_type"
                        :items="seatsTypesOptions"
                        :rules="[rules.required]"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="6" lg="4" xl="3">
                    <v-text-field
                        label="Limite posti"
                        rounded filled
                        v-model="editingItem.limit"
                        :rules="[rules.minInt(0)]"
                        type="number"
                    ></v-text-field>
                  </v-col>

                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn text color="accent" @click="save(1)">Salva</v-btn>
            <v-btn text color="accent" @click="save(2)">Salva e continua</v-btn>
            <v-btn text color="accent" @click="save(3)">Salva e aggiungi</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import utils from '../utils';
  import { mapGetters, mapActions } from 'vuex';
  import config from "../config";
  import moment from 'moment'
  import rules from "../utils/rules";
  import {seatsTypesOptions} from "../consts";
  export default {
    name: "BookingPrefForm",
    data () {
      return {
        editingItem : {
          name : '',
          seats_type : '',
          limit : 0,
        },
        rules,
        seatsTypesOptions,
      }
    },
    computed : {
      ...mapGetters(['token', 'profile', 'is_root']),
      updating() {
        return !!this.editingItem._id
      },
      title() {
        return this.$route.params.id === 'new' ? 'Nuova preferenza' : 'Modifica preferenza';
      },
      loading : {
        get() { return this.$store.state.loading; },
        set(val) { this.$store.state.loading = val; }
      }
    },
    methods : {
      ...mapActions(['sendSuccess', 'sendError', 'requestConfirm', 'logout', 'load', 'delete','get', 'update', 'insert']),
      loadData() {
        this.loading = true;
        let id = this.$route.params.id;
        this.get({collection : 'bookingprefs', id}).then((reply)=>{
          this.loading = false;
          if(reply.data) {
            this.editingItem = reply.data;
          }
        }).catch((err) => {
          this.loading = false;
          let message = err.message ? err.message : 'errore al caricamento dei dati della preferenza';
          this.sendError({message});
          this.$router.go(-1);
          if(err.statusCode === 401) {
            this.logout();
          }
        })
      },
      save( afterAction ) {
        let updating = this.updating;


        if(!this.$refs.form.validate()) {
          return;
        }
        this.editingItem.limit = utils.toInt(this.editingItem.limit, 0)

        let saveFn = updating ? this.update : this.insert;
        saveFn({collection:'bookingprefs', data : this.editingItem}).then((reply)=>{
          this.sendSuccess({ message : "Preferenza salvata"});
          switch (afterAction) {
            case 1:
              //go back
              this.$router.replace("/bookingprefs");
              break;
            case 2:
              //stay
              if(this.$route.params.id === 'new') {
                this.$router.replace("/bookingprefs/"+ reply.data._id);
              } else {
                this.editingItem = reply.data;
              }
              break;
            case 3:
              // new
              if(this.$route.params.id === 'new') {
                this.editingItem = {
                  name : '',
                  seats_type : '',
                  limit : 0,
                };
              } else {
                this.$router.replace("/bookingprefs/new");
              }
              break;
          }
        }).catch((err)=>{
          if(config.debug) console.log("Save pref error:", err);
          let message = err.message ? err.message : "Errore al salvataggio della preferenza";
          this.sendError({ message });
          if(err.statusCode === 401) {
            this.$router.replace("/");
            this.logout();
          }
        })
      }
    },
    mounted() {
      if(this.$route.params.id !== 'new') {
        this.loadData()
      }
    }
  }
</script>

<style scoped></style>
