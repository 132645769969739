<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <h2 class="display-1 ml-4">Lista pacchetti sedute</h2>
            <v-spacer />
            <v-btn color="accent" text @click="$router.push('/packets/new')">Nuovo</v-btn>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="6" md="4" lg="2">
                <v-text-field rounded solo v-model="titleSearch" label="Titolo" @input="applyFilter"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="2">
                <v-switch rounded solo v-model="ontToOneSearch" label="One-to-one" @input="applyFilter" ></v-switch>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="2">
                <v-switch rounded solo v-model="pubSearch" label="Pubblicato" @input="applyFilter" ></v-switch>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="2">
                <v-switch rounded solo v-model="noPubSearch" label="Non pubblicato" @input="applyFilter" ></v-switch>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-data-table :headers="headers" :items="items" :options.sync="pagination" :server-items-length="count" :loading="tableLoading"
                              no-data-text="Non ci sono pacchetti censiti al momento."  :footer-props="footerProps">
                  <template v-slot:item.title.it="{ item }">
                    <a :href="'./#/packets/'+item._id" ><b>{{ item.title.it }}</b></a>
                  </template>

                  <template v-slot:item.pub="{ item }">
                    <v-icon color="success" v-if="item.pub">mdi-check</v-icon>
                    <v-icon color="error" v-else>mdi-close</v-icon>
                  </template>
                  <template v-slot:item.is_one_to_one="{ item }">
                    <v-icon color="cyan" v-if="item.is_one_to_one">mdi-check</v-icon>
                    <span v-else></span>
                  </template>
                  <template v-slot:item.price="{ item }">
                    <b>{{item.price.toFixed(2)}} €</b>
                  </template>
                  <template v-slot:item.num_seats="{ item }">
                    <b>{{item.num_seats}} sedute</b>
                  </template>

                  <template v-slot:item.edit="{ item }">
                    <v-btn color="accent" dark text icon v-on:click="$router.push({ name : 'packet form', params : { id : item._id }})">
                      <v-icon dark>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <template v-slot:item.remove="{ item }">
                    <v-btn color="error" dark text icon v-on:click="requestDelete(item._id)">
                      <v-icon dark>mdi-close</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import config from "../config";
import utils from '../utils'
export default {
  name: "PacketList",
  data () {
    return {
      footerProps : {
        "items-per-page-text" : "N° risultati per pagina",
        "items-per-page-options" : [10,20,50,100],
        "items-per-page" : 10
      },
      titleSearch : null,
      ontToOneSearch : null,
      pubSearch : false,
      noPubSearch : false,
      tableLoading : false,
      count : 0,
      items : [],
      headers : [
        { text : 'Titolo', sortable: true, align: 'left', value : 'title.it' },
        { text : 'SKU', sortable: true, align: 'left', value : 'sku' },
        { text : 'One-to-one', sortable: true, align: 'left', value : 'is_one_to_one' },
        { text : 'N° sedute', sortable: true, align: 'left', value : 'num_seats' },
        { text : 'Prezzo', sortable: true, align: 'left', value : 'price' },
        { text : 'Pubblicato', sortable: true, align: 'center', value : 'pub' },
        { text : 'Modifica', sortable: false, align: 'center', value : 'edit', width : 60 },
        { text : 'Rimuovi', sortable: false, align: 'center', value : 'remove', width : 60 },
      ],
      pagination: {},
      query : {}
    }
  },
  watch: {
    pagination: {
      handler () {
        this.refresh();
      },
      deep: true
    }
  },
  computed : {
    ...mapGetters(['profile']),
  },
  methods : {
    ...mapActions(['sendSuccess', 'sendError', 'requestConfirm', 'logout', 'load', 'get', 'delete','refreshCollection']),
    applyFilter() {
      this.query = {};
      this.query.title = this.titleSearch ? this.titleSearch : undefined;
      this.query.onetoone = this.ontToOneSearch ? true : undefined;
      this.query.pub = undefined;
      if(this.pubSearch) this.query.pub = true;
      if(this.noPubSearch) this.query.pub = false;
      this.refresh();
    },
    requestDelete( id ) {
      this.requestConfirm({ title : 'Richiesta cancellazione', message : 'Sei sicuro di voler eliminare definitivamente questo pacchetto sedute?', callback: ( accepted )=>{
          if(accepted) {
            this.delete({ collection : 'packets', id }).then(()=>{
              this.refresh();
              this.sendSuccess({message:'Pacchetto sedute eliminato!'});
            }).catch((err)=>{
              let message = err.message ? err.message : 'errore alla cancellazione del pacchetto sedute';
              this.sendError({ message });
              if(config.debug) console.error("Errore di cancellazione del pacchetto",err);
              if(err.statusCode === 401) {
                this.logout();
              }
            })
          }
        }});
    },
    refresh() {
      this.tableLoading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.pagination;
      let sort = null;
      let order = null;
      if(sortBy.length) {
        sort = sortBy[0];
        order = 'ASC';
        if(sortDesc[0]) order = 'DESC';
      }

      let filter = {
        query : this.query,
        limit : itemsPerPage,
        page, sort, order
      };

      this.load({ collection : 'packets', filter}).then((reply)=>{
        this.tableLoading = false;
        if(reply.data) {
          this.count = reply.count;
          this.items = reply.data;
        }
      }).catch((err)=>{
        this.tableLoading = false;
        this.sendError({message:"Errore al caricamento dei pacchetti."});
        if(config.debug) console.error("Errore al caricamento dei pacchetti: " + err);
        if(err.statusCode === 401) {
          this.logout();
        }
      })
    }
  },
  mounted() {
    this.refresh();
  }
}
</script>

<style scoped>

</style>