<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <h2 class="display-1 ml-4">{{ title }}</h2>
            <v-spacer />
            <v-btn text color="accent" @click="save(1)">Salva</v-btn>
            <v-btn text color="accent" @click="save(2)">Salva e continua</v-btn>
            <v-btn text color="accent" @click="save(3)">Salva e aggiungi</v-btn>
          </v-card-title>
          <v-card-text>
            <v-container fluid>
              <v-row>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-text-field
                          label="Titolo*"
                          rounded filled
                          v-model="editingItem.title.it"
                          :error="titleError"
                          :error-messages="requiredErrorMessages(titleError)"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-textarea
                          label="Descrizione*"
                          rounded filled
                          v-model="editingItem.description.it"
                          :error="descError"
                          :error-messages="requiredErrorMessages(descError)"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-autocomplete
                          label="Audio*"
                          :items="audioOptions"
                          rounded filled
                          v-model="editingItem.audio_id"
                          :error="audioError"
                          :error-messages="requiredErrorMessages(audioError)"
                          prepend-icon="mdi-cloud-upload"
                          @click:prepend="openUploadDialog('audio')"
                  ></v-autocomplete>
                  <audio controls style="width: 100%" :src="selectedAudioUrl"/>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-autocomplete
                          label="Video*"
                          :items="videoOptions"
                          rounded filled
                          v-model="editingItem.video_id"
                          :error="videoError"
                          :error-messages="requiredErrorMessages(videoError)"
                          prepend-icon="mdi-cloud-upload"
                          @click:prepend="openUploadDialog('video')"
                  ></v-autocomplete>
                  <v-responsive :aspect-ratio="16/9" style="background: #000">
                    <video v-if="editingItem.video_id" :src="selectedVideoUrl"  style="width: 100%; height: 100%;" controls/>
                  </v-responsive>
                </v-col>


                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-text-field
                          type="number"
                          label="Inizio loop (sec)"
                          rounded filled
                          v-model="editingItem.video_loop_start"
                          min="0"
                          :max="videoLoopMax"
                          :error="loopStartError"
                          :error-messages="requiredErrorMessages(loopStartError)"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-text-field
                          type="number"
                          label="Fine loop (sec)"
                          rounded filled
                          v-model="editingItem.video_loop_end"
                          min="0"
                          :max="videoLoopMax"
                          :error="loopEndError"
                          :error-messages="requiredErrorMessages(loopEndError)"
                  ></v-text-field>
                  <v-responsive :aspect-ratio="16/9" style="background: #000">
                    <video ref="videoLoop" v-if="editingItem.video_id" :src="selectedVideoUrl"  style="width: 100%; height: 100%;" autoplay loop muted
                           @durationchange="onVideoLoopMetadata" @timeupdate="onVideoLoopProgress"/>
                  </v-responsive>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn text color="accent" @click="save(1)">Salva</v-btn>
            <v-btn text color="accent" @click="save(2)">Salva e continua</v-btn>
            <v-btn text color="accent" @click="save(3)">Salva e aggiungi</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="uploadDialog" persistent max-width="500">
      <v-card>
        <v-card-title class="headline">Carica {{uploadMedia.media}}</v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col cols="12" >
                <v-text-field
                        label="Nome*"
                        rounded filled
                        v-model="uploadMedia.name"
                        :error="mediaNameError"
                        :error-messages="requiredErrorMessages(mediaNameError)"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-file-input v-model="file" chips rounded filled show-size
                              :accept="acceptMedia" label="File"
                              placeholder="Clicca qui per scegliere il file"></v-file-input>
              </v-col>
              <v-col cols="12" >
                <v-text-field
                        label="Url"
                        rounded filled
                        persistent-hint
                        hint="In alternativa al file upload"
                        v-model="uploadMedia.url"
                        :disabled="file != null"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text :disabled="uploading" @click="uploadDialog = false">Annulla</v-btn>
          <v-btn color="error" :disabled="uploading" :loading="uploading" text @click="beginUpload()">Inserisci</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import config from "../config";
  import utils from "../utils";
  export default {
    name: "ExerciseForm",
    data () {
      return {
        editingItem : {
          title : { it : '' },
          description : { it : '' },
          audio_id : null,
          video_id : null,
          video_loop_start: 0,
          video_loop_end: 0,
        },
        file : null,
        uploading : false,
        uploadDialog : false,
        uploadMedia: {
          name : '',
          media : 'video',
          url : '',
        },
        videoLoopMax : 0,
        loopStartError: false,
        loopEndError: false,
        mediaNameError : false,
        audioError : false,
        videoError : false,
        titleError : false,
        descError : false,
      }
    },
    computed : {
      ...mapGetters(['token', 'profile', 'is_root', 'media']),
      updating() {
        return !!this.editingItem._id
      },
      title() {
        return this.$route.params.id === 'new' ? 'Nuovo esercizio' : 'Modifica esercizio';
      },
      loading : {
        get() { return this.$store.state.loading; },
        set(val) { this.$store.state.loading = val; }
      },
      audioOptions() {
        return this.media.filter(( item )=> {
          return item.media === "audio"
        }).map((item) => {
          return { text : item.name, value: item._id }
        });
      },
      videoOptions() {
        return this.media.filter(( item )=> {
          return item.media === "video"
        }).map((item) => {
          return { text : item.name, value: item._id }
        });
      },
      selectedVideoUrl() {
        let media = this.getMedia(this.editingItem.video_id);
        if(!media) return "";
        return media.url || "";
      },
      selectedAudioUrl() {
        let media = this.getMedia(this.editingItem.audio_id);
        if(!media) return "";
        return media.url || "";
      },
      acceptMedia() {
        return this.uploadMedia.media === 'video' ? '.mp4' : '.mp3'
      }
    },
    methods : {
      ...mapActions(['sendSuccess', 'sendError', 'requestConfirm', 'logout', 'load', 'delete','get', 'update', 'upload', 'insert','refreshCollection']),
      onVideoLoopMetadata(e) {
        console.log("durationchange", e.target.duration)
        this.videoLoopMax = parseInt(e.target.duration,10);
        if(this.editingItem.video_loop_end == 0) this.editingItem.video_loop_end = parseInt(e.target.duration,10);
      },
      onVideoLoopProgress(e) {
        //console.log(e.target.duration)
        const progress = e.target.currentTime;
        if(progress >= this.editingItem.video_loop_end) {
          e.target.currentTime = this.editingItem.video_loop_start
        }
      },
      requiredErrorMessages(err) {
        if(err) return ["Campo richiesto"];
        return []
      },
      openUploadDialog( mediatype ) {
        this.uploadMedia.media = mediatype;
        this.uploadDialog = true;
        this.uploading = false;
      },
      beginUpload() {
        this.mediaNameError = false;
        if(!this.uploadMedia.name) {
          this.mediaNameError = true;
          return
        }
        this.uploading = true;
        this.insert({collection:"media", data: this.uploadMedia}).then((reply)=>{
          if(reply.data) {
            if(reply.data.media === 'video') this.editingItem.video_id = reply.data._id;
            if(reply.data.media === 'audio') this.editingItem.audio_id = reply.data._id;
            if(this.file) {
              let formData = new FormData();
              formData.append("media", this.file);
              this.upload({ where : 'media/' + reply.data._id + '/upload', formData }).then(() => {
                this.refreshCollection({collection: 'media'});
                this.uploading = false;
                this.uploadDialog = false;
                this.file = null;
                this.uploadMedia.name = '';
                this.uploadMedia.url = '';
              }).catch(onError);
              return
            }
            this.refreshCollection({collection: 'media'});
            this.sendSuccess({message: "Media inserito."})
          } else {
            this.sendError({message : 'errore all\'inserimento del media'});
          }
          this.uploading = false;
          this.uploadDialog = false;
        }).catch(onError);

        let onError = (err) => {
          this.uploading = false;
          let message = err.message ? err.message : 'errore all\'inserimento del media';
          this.sendError({message});
          if(err.statusCode === 401) {
            this.logout();
          }
        }
      },
      getMedia( id ) {
        for(let i = 0; i < this.media.length; i++) {
          if(this.media[i]._id === id) {
            return this.media[i];
          }
        }
        return null
      },
      loadData() {
        this.loading = true;
        let id = this.$route.params.id;
        this.get({collection : 'exercises', id}).then((reply)=>{
          this.loading = false;
          if(reply.data) {
            this.editingItem = reply.data;
          }
        }).catch((err) => {
          this.loading = false;
          let message = err.message ? err.message : 'errore al caricamento dei dati esercizio';
          this.sendError({message});
          this.$router.go(-1);
          if(err.statusCode === 401) {
            this.logout();
          }
        })
      },
      clearErrors() {
        this.videoError = false;
        this.titleError = false;
        this.descError = false;
        this.audioError = false;
      },
      save( afterAction ) {
        this.clearErrors();
        let updating = this.updating;


        if(!this.editingItem.title.it) {
          this.titleError = true;
          return;
        }
        if(!this.editingItem.description.it) {
          this.descError = true;
          return;
        }
        if(!this.editingItem.audio_id) {
          this.audioError = true;
          return;
        }
        if(!this.editingItem.video_id) {
          this.videoError = true;
          return;
        }

        this.editingItem.video_loop_start = utils.toInt(this.editingItem.video_loop_start, 0);
        this.editingItem.video_loop_end = utils.toInt(this.editingItem.video_loop_end, this.videoLoopMax);


        let saveFn = updating ? this.update : this.insert;
        saveFn({collection:'exercises', data : this.editingItem}).then((reply)=>{
          this.sendSuccess({ message : "Esercizio salvato"});
          switch (afterAction) {
            case 1:
              //go back
              this.$router.replace("/exercises");
              break;
            case 2:
              //stay
              if(this.$route.params.id === 'new') {
                this.$router.replace("/exercises/"+ reply.data._id);
              } else {
                this.editingItem = reply.data;
              }
              break;
            case 3:
              // new
              if(this.$route.params.id === 'new') {
                this.editingItem = {
                  title : { it : '' },
                  description : { it : '' },
                  audio_id : null,
                  video_id : null,
                  video_loop_start: 0,
                  video_loop_end: 0,
                };
              } else {
                this.$router.replace("/exercises/new");
              }
              break;
          }
        }).catch((err)=>{
          if(config.debug) console.log("Save exercise error:", err);
          let message = err.message ? err.message : "errore al salvataggio dell'esercizio";
          this.sendError({ message });
          if(err.statusCode === 401) {
            this.$router.replace("/");
            this.logout();
          }
        })
      }
    },
    mounted() {
      this.refreshCollection({ collection : 'media' });
      if(this.$route.params.id !== 'new') {
        this.loadData()
      }
    }
  }
</script>

<style scoped></style>
