<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <h2 class="display-1 ml-4">Visite mediche</h2>
            <v-spacer />
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="6" md="4" lg="2">
                <v-text-field rounded solo v-model="nameSearch" label="Nome" @input="applyFilter"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="2">
                <v-text-field rounded solo v-model="emailSearch" label="Email" @input="applyFilter"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="2">
                <v-select rounded solo v-model="certExpSearch" label="Scadenza certificato" @input="applyFilter" clearable :items="[{text:'Cert. scaduto',value:'true'},{text:'Cert. valido',value:'false'}]"></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="2">
                <v-switch v-model="activeUserSearch" label="Filtro magico*" @change="applyFilter" hint="*filtra per utenti con abbonamenti attivi" persistent-hint></v-switch>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-data-table :headers="headers" :items="items" :options.sync="pagination" :server-items-length="count" :loading="tableLoading"
                              no-data-text="Non ci sono utenti censiti al momento."  :footer-props="footerProps" dense>

                  <template v-slot:item.surname="{ item }">
                    <a :href="'./#/users/'+item._id" ><b>{{ item.surname }}</b>,  {{item.name}}</a>
                  </template>
                  <template v-slot:item.date_of_birth="{ item }">
                    {{ calcAge(item.date_of_birth) }}
                  </template>
                  <template v-slot:item.certificate="{ item }">
                    <strong v-if="item.certificate" class="success--text">Accettato</strong>
                    <strong v-else class="error--text">Non accettato</strong>
                  </template>
                  <template v-slot:item.medical_cert="{ item }">
                    <a v-if="item.medical_cert" class="text-decoration-underline" @click="downloadMedicalCert(item._id)"><strong class="success--text">Sì</strong></a>
                    <strong v-else class="error--text">No</strong>
                  </template>
                  <template v-slot:item.medical_cert_expiration="{ item }">
                    <span v-if="item.medical_cert_expiration">{{ formatDate(item.medical_cert_expiration) }}</span>
                    <span v-else>-</span>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import config from "../config";
import moment from 'moment'
import utils from '../utils'
export default {
  name: "UserList",
  data () {
    return {
      footerProps : {
        "items-per-page-text" : "N° risultati per pagina",
        "items-per-page-options" : [50,100, -1],
        "items-per-page" : 50
      },
      nameSearch : null,
      emailSearch : null,
      certExpSearch : null,
      activeUserSearch : true,
      roleSearch : null,
      tableLoading : false,
      count : 0,
      items : [],
      headers : [
        { text : 'Cognome e nome', sortable: true, align: 'left', value : 'surname' },
        { text : 'Email', sortable: true, align: 'left', value : 'email' },
        { text : 'Telefono', sortable: true, align: 'left', value : 'phone' },
        { text : 'Età', sortable: false, align: 'left', value : 'date_of_birth' },
        { text : 'Accettazione responsabilità', sortable: true, align: 'center', value : 'certificate', width : 60 },
        { text : 'Certificato', sortable: true, align: 'center', value : 'medical_cert', width : 60 },
        { text : 'Scadenza', sortable: true, align: 'center', value : 'medical_cert_expiration'},
      ],
      roleOptions : config.roles,
      pagination: {},
      query : {
        role : 'user',
        active : 'true'
      }
    }
  },
  watch: {
    pagination: {
      handler () {
        this.refresh();
      },
      deep: true
    }
  },
  computed : {
    ...mapGetters(['profile','token'])
  },
  methods : {
    ...mapActions(['sendSuccess', 'sendError', 'requestConfirm', 'logout', 'load', 'delete','get']),
    calcAge( birth ) {
      let age = utils.calcAge(birth);
      return age + " anni";
    },
    formatDate : utils.formatDate,
    downloadMedicalCert(id) {
      window.open(`${config.server}/api/users/${id}/medical?t=${this.token}`)
    },
    applyFilter() {

      this.query = {};
      this.query.fullname = this.nameSearch ? this.nameSearch : undefined;
      this.query.email = this.emailSearch ? this.emailSearch : undefined;
      this.query.role = this.roleSearch ? this.roleSearch : undefined;
      this.query.certexp = this.certExpSearch ? this.certExpSearch : undefined;
      this.query.active = this.activeUserSearch ? 'true' : 'false';
      this.refresh();
    },
    requestDelete( id ) {
      this.requestConfirm({ title : 'Richiesta cancellazione', message : 'Sei sicuro di voler eliminare definitivamente questo utente? Eliminerai anche tutti i suoi acquisti (se presenti).', callback: ( accepted )=>{
          if(accepted) {
            this.delete({ collection : 'users', id }).then(()=>{
              this.refresh();
              this.sendSuccess({message:'Utente eliminato!'});
            }).catch((err)=>{
              let message = err.message ? err.message : 'errore alla cancellazione dell\'utente';
              this.sendError({ message });
              if(config.debug) console.error("Errore di cancellazione dell'utente",err);
              if(err.statusCode === 401) {
                this.logout();
              }
            })

          }
        }});
    },
    refresh() {
      this.tableLoading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.pagination;
      let sort = null;
      let order = null;
      if(sortBy.length) {
        sort = sortBy[0];
        order = 'ASC';
        if(sortDesc[0]) order = 'DESC';
      }

      let filter = {
        query : this.query,
        limit : itemsPerPage,
        page, sort, order
      };

      this.load({ collection : 'users', filter}).then((reply)=>{
        this.tableLoading = false;
        if(reply.data) {
          this.count = reply.count;
          this.items = reply.data;
        }
      }).catch((err)=>{
        this.tableLoading = false;
        this.sendError({message:"Errore al caricamento degli utenti."});
        if(config.debug) console.error("Errore al caricamento degli utenti: " + err);
        if(err.statusCode === 401) {
          this.logout();
        }
      })
    }
  },
  mounted() {
    this.refresh();
  }
}
</script>

<style scoped>

</style>