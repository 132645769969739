<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-card-title>
                        <h2 class="display-1 ml-4">Lista media</h2>
                        <v-spacer />
                        <v-btn color="accent" text @click="$router.push('/media/new')">Nuovo</v-btn>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="6" md="4" lg="2">
                                <v-text-field rounded solo v-model="nameSearch" label="Nome" @input="applyFilter"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4" lg="2">
                                <v-select rounded solo v-model="typeSearch" label="Tipologia" @input="applyFilter" :items="typeOptions" clearable></v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-data-table :headers="headers" :items="items" :options.sync="pagination" :server-items-length="count" :loading="tableLoading"
                                              no-data-text="Non ci sono media censiti al momento."  :footer-props="footerProps">

                                    <template v-slot:item.name="{ item }">
                                        <a :href="'./#/media/'+item._id" ><b>{{ item.name }}</b></a>
                                    </template>
                                    <template v-slot:item.media="{ item }">
                                        <span v-if="item.media === 'cover'">copertina</span>
                                        <span v-else-if="item.media==='icon'">icona</span>
                                        <span v-else-if="item.media==='gallery'">galleria</span>
                                        <span v-else>{{item.media}}</span>
                                    </template>
                                    <template v-slot:item.url="{ item }">
                                        <a :href="item.url" >{{ item.url }}</a>
                                    </template>
                                    <template v-slot:item.edit="{ item }">
                                        <v-btn color="accent" dark text icon v-on:click="$router.push({ name : 'media form', params : { id : item._id }})">
                                            <v-icon dark>mdi-pencil</v-icon>
                                        </v-btn>
                                    </template>
                                    <template v-slot:item.remove="{ item }">
                                        <v-btn color="error" dark text icon v-on:click="requestDelete(item._id)">
                                            <v-icon dark>mdi-close</v-icon>
                                        </v-btn>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>

            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import config from "../config";
    import moment from 'moment'
    import utils from '../utils'
    export default {
        name: "MediaList",
        data () {
            return {
                footerProps : {
                    "items-per-page-text" : "N° risultati per pagina",
                    "items-per-page-options" : [10,20,50,100],
                    "items-per-page" : 10
                },
                nameSearch : null,
                typeSearch : null,
                tableLoading : false,
                count : 0,
                items : [],
                headers : [
                    { text : 'Nome', sortable: true, align: 'left', value : 'name' },
                    { text : 'Tipologia', sortable: true, align: 'left', value : 'media' },
                    { text : 'Url', sortable: false, align: 'left', value : 'url' },
                    { text : 'Modifica', sortable: false, align: 'center', value : 'edit', width : 60 },
                    { text : 'Rimuovi', sortable: false, align: 'center', value : 'remove', width : 60 },
                ],
                typeOptions : config.mediatypes,
                pagination: {},
                query : {}
            }
        },
        watch: {
            pagination: {
                handler () {
                    this.refresh();
                },
                deep: true
            }
        },
        computed : {
            ...mapGetters(['profile'])
        },
        methods : {
            ...mapActions(['sendSuccess', 'sendError', 'requestConfirm', 'logout', 'load', 'delete']),
            applyFilter() {
                this.query = {};
                this.query.name = this.nameSearch ? this.nameSearch : undefined;
                this.query.media = this.typeSearch ? this.typeSearch : undefined;
                this.refresh();
            },
            requestDelete( id ) {
                this.requestConfirm({ title : 'Richiesta cancellazione', message : 'Sei sicuro di voler eliminare definitivamente questo media?', callback: ( accepted )=>{
                        if(accepted) {
                            this.delete({ collection : 'media', id }).then(()=>{
                                this.refresh();
                                this.sendSuccess({message:'Media eliminato!'});
                            }).catch((err)=>{
                                let message = err.message ? err.message : 'errore alla cancellazione del media';
                                this.sendError({ message });
                                if(config.debug) console.error("Errore di cancellazione del media",err);
                                if(err.statusCode === 401) {
                                    this.logout();
                                }
                            })

                        }
                    }});
            },
            refresh() {
                this.tableLoading = true;
                const { sortBy, sortDesc, page, itemsPerPage } = this.pagination;
                let sort = null;
                let order = null;
                if(sortBy.length) {
                    sort = sortBy[0];
                    order = 'ASC';
                    if(sortDesc[0]) order = 'DESC';
                }

                let filter = {
                    query : this.query,
                    limit : itemsPerPage,
                    page, sort, order
                };

                this.load({ collection : 'media', filter}).then((reply)=>{
                    this.tableLoading = false;
                    if(reply.data) {
                        this.count = reply.count;
                        this.items = reply.data;
                    }
                }).catch((err)=>{
                    this.tableLoading = false;
                    this.sendError({message:"Errore al caricamento del media."});
                    if(config.debug) console.error("Errore al caricamento del media: " + err);
                    if(err.statusCode === 401) {
                        this.logout();
                    }
                })
            }
        },
        mounted() {
            this.refresh();
        }
    }
</script>

<style scoped>

</style>