<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-card-title>
                        <h2 class="display-1 ml-4">Lista tessere</h2>
                        <v-spacer />
                        <v-file-input v-model="file" chips rounded solo show-size
                                      style="width: 200px"
                                      accept=".xls" label="Excel Coni"
                                      hide-details
                                      placeholder="Clicca qui per scegliere il file"></v-file-input>
                        <v-btn :disabled="!file" color="accent" text @click="importExcel()">Importa</v-btn>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="6" md="4" lg="2">
                                <v-text-field rounded solo v-model="nameSearch" label="Nome" @input="applyFilter"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4" lg="2">
                                <v-text-field rounded solo v-model="numberSearch" label="N° tessera" @input="applyFilter"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4" lg="2">
                                <v-text-field rounded solo v-model="fiscalSearch" label="Cod. fiscale" @input="applyFilter"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4" lg="2">
                                <v-select rounded solo v-model="expiredSearch" label="Scadute" :items="expiredOptions" @input="applyFilter" clearable></v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-data-table :headers="headers" :items="items" :options.sync="pagination" :server-items-length="count" :loading="tableLoading"
                                              no-data-text="Non ci sono tessere censite al momento."  :footer-props="footerProps">

                                    <template v-slot:item.number="{ item }">
                                        <a :href="'./#/cards/'+item._id" ><b>{{ item.number }}</b></a>
                                    </template>
                                    <template v-slot:item.surname="{ item }">
                                        <a :href="'./#/cards/'+item._id" ><b>{{ item.surname }}</b> {{ item.name }}</a>
                                    </template>
                                    <template v-slot:item.user._id="{ item }">
                                        <v-icon v-if="item.user" color="success">mdi-check</v-icon>
                                        <v-icon v-else color="error">mdi-close</v-icon>
                                    </template>
                                    <template v-slot:item.edit="{ item }">
                                        <v-btn color="accent" dark text icon v-on:click="$router.push({ name : 'card view', params : { id : item._id }})">
                                            <v-icon dark>mdi-eye</v-icon>
                                        </v-btn>
                                    </template>
                                    <template v-slot:item.remove="{ item }">
                                        <v-btn color="error" dark text icon v-on:click="requestDelete(item._id)">
                                            <v-icon dark>mdi-close</v-icon>
                                        </v-btn>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>

            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import config from "../config";
    import moment from 'moment'
    import utils from '../utils'
    export default {
        name: "CardList",
        data () {
            return {
                file: null,
                footerProps : {
                    "items-per-page-text" : "N° risultati per pagina",
                    "items-per-page-options" : [10,20,50,100],
                    "items-per-page" : 10
                },
                nameSearch : null,
                numberSearch : null,
                fiscalSearch : null,
                expiredSearch : null,
                expiredOptions : [
                    { text: "Sì", value : true},
                    { text: "No", value : false},
                ],
                tableLoading : false,
                count : 0,
                items : [],
                headers : [
                    { text : 'N° Tessera', sortable: true, align: 'left', value : 'number' },
                    { text : 'Cognome e nome', sortable: true, align: 'left', value : 'surname' },
                    { text : 'Cod. fiscale', sortable: true, align: 'left', value : 'fiscal_code' },
                    { text : 'Assicurazione', sortable: true, align: 'left', value : 'insurance' },
                    { text : 'Scadenza', sortable: true, align: 'left', value : 'expiration' },
                    { text : 'Registrato', sortable: true, align: 'center', value : 'user._id' },
                    { text : 'Modifica', sortable: false, align: 'center', value : 'edit', width : 60 },
                    { text : 'Rimuovi', sortable: false, align: 'center', value : 'remove', width : 60 },
                ],
                pagination: {},
                query : {}
            }
        },
        watch: {
            pagination: {
                handler () {
                    this.refresh();
                },
                deep: true
            }
        },
        computed : {
            ...mapGetters(['profile'])
        },
        methods : {
            ...mapActions(['sendSuccess', 'sendError', 'requestConfirm', 'logout', 'load', 'delete','upload']),
            applyFilter() {
                this.query = {};
                this.query.name = this.nameSearch ? this.nameSearch : undefined;
                this.query.number = this.numberSearch ? this.numberSearch : undefined;
                this.query.cf = this.fiscalSearch ? this.fiscalSearch : undefined;
                if(this.expiredSearch === true || this.expiredSearch === false) {
                    this.query.expired = this.expiredSearch === true
                } else {
                    this.query.expired = undefined;
                }
                this.refresh();
            },
            requestDelete( id ) {
                this.requestConfirm({ title : 'Richiesta cancellazione', message : 'Sei sicuro di voler eliminare definitivamente questa tessera?', callback: ( accepted )=>{
                        if(accepted) {
                            this.delete({ collection : 'cards', id }).then(()=>{
                                this.refresh();
                                this.sendSuccess({message:'Tessera eliminata!'});
                            }).catch((err)=>{
                                let message = err.message ? err.message : 'errore alla cancellazione della tessera';
                                this.sendError({ message });
                                if(config.debug) console.error("Errore di cancellazione della tessera",err);
                                if(err.statusCode === 401) {
                                    this.logout();
                                }
                            })

                        }
                    }});
            },
            refresh() {
                this.tableLoading = true;
                const { sortBy, sortDesc, page, itemsPerPage } = this.pagination;
                let sort = null;
                let order = null;
                if(sortBy.length) {
                    sort = sortBy[0];
                    order = 'ASC';
                    if(sortDesc[0]) order = 'DESC';
                }

                let filter = {
                    query : this.query,
                    limit : itemsPerPage,
                    page, sort, order
                };

                this.load({ collection : 'cards', filter}).then((reply)=>{
                    this.tableLoading = false;
                    if(reply.data) {
                        this.count = reply.count;
                        this.items = reply.data;
                    }
                }).catch((err)=>{
                    this.tableLoading = false;
                    this.sendError({message:"Errore al caricamento delle tessere."});
                    if(config.debug) console.error("Errore al caricamento delle tessere: " + err);
                    if(err.statusCode === 401) {
                        this.logout();
                    }
                })
            },
            importExcel() {
                if(!this.file) {
                    return
                }
                let formData = new FormData();
                formData.append("file", this.file);
                this.upload( { where : "cards/import", formData }).then((reply) => {
                    if(reply.data) {
                        this.sendSuccess({message: "Importate " + reply.data.length + " tessere!"});
                        this.refresh();
                        return
                    }
                    this.sendSuccess({message: "Nessuna tessera importata!"})
                }).catch((err) => {
                    this.sendError({message: err})
                });
            }
        },
        mounted() {
            this.refresh();
        }
    }
</script>

<style scoped>

</style>