<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <h2 class="display-1">Pacchetti sedute dell'utente "{{userName}}"</h2>
            <v-spacer />
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" v-for="item in seats" :key="item._id">

                <v-card >
                  <v-card-text>
                    <h2>Pacchetto {{item.consumable ? 'a sedute limitate' : 'a scadenza temporale'}} con accesso a:
                      <v-chip-group>
                        <v-chip small class="white--text font-weight-bold text-uppercase" v-for="(st, j) in item.seats_types" :key="`valid_us_${item._id}_st_${j}`" :color="getSeatsTypeColor(st)">{{getSeatsTypeLabel(st)}}</v-chip>
                      </v-chip-group>
                    </h2>
                    <h3 v-if="item.expiration" class="font-weight-bold">Scadenza: {{formatDate(item.expiration)}}</h3>
                    <h3 v-else class="font-weight-bold">Scadenza: -</h3>
                    <v-row v-if="item.consumable" no-gutters class="mt-3">
                      <v-col cols="12">
                        <h3 class="font-weight-bold">N° sedute utilizzabili <span class="accent--text">{{item.available - item.used}}</span> di {{item.available}} a disposizione ({{item.used}} utilizzate)</h3>
                        <small>Qui puoi aggiungere o rimuovere sedute a disposizione per questo pacchetto (non puoi scendere sotto il numero di sedute utilizzate)</small>
                      </v-col>
                      <v-col cols="12" class="pt-1">
                        <div class="d-flex">
                          <v-btn rounded small color="accent" class="mr-2" @click="editAvailable(item,-10)" :loading="seatsLoading" :disabled="seatsLoading">-10</v-btn>
                          <v-btn rounded small color="accent" class="mr-2" @click="editAvailable(item,-1)" :loading="seatsLoading" :disabled="seatsLoading">-1</v-btn>
                          <v-btn rounded small color="accent" class="mr-2" @click="editAvailable(item,1)" :loading="seatsLoading" :disabled="seatsLoading">+1</v-btn>
                          <v-btn rounded small color="accent" class="mr-2" @click="editAvailable(item,10)" :loading="seatsLoading" :disabled="seatsLoading">+10</v-btn>
                        </div>
                      </v-col>
                    </v-row>


                    <h3 class="mt-3 mb-2">Imposta una data scadenza</h3>
                    <v-row >
                      <v-col cols="12" md="6" lg="4">
                        <date-field label="Nuova scadenza"  v-model="item.expiration" ></date-field>
                      </v-col>
                      <v-col cols="12" md="6" lg="4">
                        <v-btn rounded color="accent" small class="my-2" @click="saveItem(item)" :loading="seatsLoading" :disabled="seatsLoading">Salva</v-btn>
                      </v-col>

                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn rounded color="error" class="ml-3 mb-2" @click="deleteItem(item)">Elimina questo pacchetto sedute</v-btn>
                  </v-card-actions>
                </v-card>
                <!--<p>{{item}}</p>-->

              </v-col>

              <v-col cols="12" >
                <v-divider class="my-4"></v-divider>
                <!--<p>{{item}}</p>-->
                <h2>Aggiungi un pacchetto sedute</h2>

                <v-form ref="customForm" @submit.prevent="onAddCustomSeats()" class="mt-4">
                  <v-row>
                    <v-col cols="12" md="6" lg="4" xl="3" >
                      <v-select filled rounded label="Tipologia custom" v-model="addSeatsCustomType"  :items="addSeatsCustomTypeOptions"></v-select>
                    </v-col>
                    <v-col cols="12" md="6" lg="4"  >
                      <v-select filled rounded label="Tipo Sedute" v-model="addSeatsCustom.seats_types"
                                :items="seatsTypesOptions" multiple chips deletable-chips :rules="[rules.required, rules.notEmpty]"></v-select>
                    </v-col>
                    <v-col cols="12" class="mx-0 my-0 px-0 py-0"></v-col>

                    <v-col cols="12" md="6" lg="4" xl="3" v-if="addSeatsCustomType === 'consumable'">
                      <v-text-field type="number" filled rounded step="1" min="1" label="Durata (in giorni dal primo utilizzo)"
                                    v-model="addSeatsCustom.duration" :rules="[rules.required, rules.minInt(1)]"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" lg="4" xl="3" v-if="addSeatsCustomType !== 'consumable'">
                      <v-text-field type="date" filled rounded label="Scadenza" v-model="addSeatsCustom.expiration" :rules="[rules.required]"></v-text-field>
                    </v-col>

                    <v-col cols="12" md="6" lg="4" xl="3" v-if="addSeatsCustomType !== 'expiration'">
                      <v-text-field type="number" filled rounded step="1" min="1" label="N° sedute" v-model="addSeatsCustom.available" :rules="[rules.required, rules.minInt(1)]"></v-text-field>
                    </v-col>

                    <v-col cols="12" md="6" lg="4" xl="3" >
                      <v-btn rounded small color="accent" type="submit">Inserisci Pacchetto</v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-col>

            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import utils from "../utils";
import {mapActions, mapGetters} from "vuex";
import {seatsTypes, seatsTypesOptions} from "../consts";
import DateField from "../components/DateField.vue";
import rules from "../utils/rules";
import {DateTime} from "luxon";

export default {
  name: "UserSeats",
  components: {DateField},
  data() {
    return {
      rules,
      seatsLoading : false,
      user : null,
      seats : [],

      addSeatsCustom : {
        user_id : null,
        consumable : true,
        seats_types : [],
        available : 0,
        used : 0,
        duration : 365,
        expiration : null,
      },
      addSeatsCustomTypeOptions : [
        { text : 'Con sedute a consumo', value : 'consumable' },
        { text : 'A scadenza fissa', value : 'expiration' },
        { text : 'Sedute a consumo + scadenza fissa', value : 'both' }
      ],
      addSeatsCustomType : 'consumable',
      seatsTypesOptions,
    }
  },
  watch : {
    addSeatsCustomType() {
      this.addSeatsCustom.consumable = this.addSeatsCustomType !== 'expiration'

      if(this.addSeatsCustomType === 'consumable') {
        this.addSeatsCustom.expiration = null
        this.addSeatsCustom.duration = 365
      } else {
        this.addSeatsCustom.duration = 0
      }
      if(this.addSeatsCustomType === 'expiration') {
        this.addSeatsCustom.available = 0
      }
    }
  },
  computed :{
    ...mapGetters(['kits','products']),
    loading: {
      get() {
        return this.$store.state.loading;
      },
      set(val) {
        this.$store.state.loading = val;
      }
    },
    userName() {
      return this.user ? `${this.user.name} ${this.user.surname}` : '-';
    },
  },
  methods : {
    ...mapActions(['sendError','sendSuccess','get','logout','load','insert','delete','requestConfirm','refreshCollection']),
    formatDate : utils.formatDate,
    async editAvailable(item, num) {
      const target = item.available + num
      if(target < item.used) {
        this.sendError({message : 'Non puoi rimuovere ulteriori sedute'})
        return;
      }
      item.available = target
      await this.saveItem(item)
    },
    async saveItem(item) {
      console.log(item.expiration)
      if(!item.consumable && !item.expiration) {
        this.sendError({message:'Un pacchetto a scadenza temporale deve avere una data di scadenza!'})
        return
      }

      this.seatsLoading = true;

      try {
        await this.insert({collection:'userseats', data : item})
        this.seatsLoading = false;
        await this.loadData()
      } catch (err) {
        this.seatsLoading = false;
        let message = err.message
            ? err.message
            : "errore al salvataggio dei dati della seduta";
        this.sendError({ message });
        this.$router.go(-1);
        if (err.statusCode === 401) {
          this.logout();
        }
      }
    },
    deleteItem(item) {
      this.requestConfirm({title:'Richiesta conferma', message:'Sei sicuro di voler eliminare questo pacchetto di sedute?', callback: async ok => {
          if(ok) {
            this.seatsLoading = true;
            try {
              await this.delete({collection:'userseats', id:item._id})
              this.seatsLoading = false;
              await this.loadData()
            } catch (err) {
              this.seatsLoading = false;
              let message = err.message
                  ? err.message
                  : "errore al caricamento dei dati utente";
              this.sendError({ message });
              this.$router.go(-1);
              if (err.statusCode === 401) {
                this.logout();
              }
            }
          }
        }
      })
    },
    getSeatsTypeLabel( st ) {
      const opt = utils.getObjectInArray(seatsTypesOptions, 'value', st)
      return opt ? opt.text : st;
    },
    getSeatsTypeColor( st ) {
      const opt = utils.getObjectInArray(seatsTypesOptions, 'value', st)
      return opt ? opt.color : '';
    },
    getSeatsName(s) {
      switch (s) {
        case seatsTypes.topgymexperience:
          return "Top Gym Experience"
        case seatsTypes.opengym:
          return "Open Gym"
        case seatsTypes.corsi:
          return "Sala corsi"
        case seatsTypes.gym:
          return "Bike indoor"
        case seatsTypes.vacu:
          return "Vacu"
      }
      return s
    },
    async loadData() {
      this.loading = true;
      let id = this.$route.params.id;
      try {
        let { data } = await this.get({ collection: "users", id })

        if (data) {
          this.user = data;
        }

        let reply = await this.load({collection:'userseats', filter: utils.pageFilter({user:id})})
        if(reply.data && reply.data.length) {
          this.seats = reply.data
        }

        this.loading = false;


      } catch (err) {
        this.loading = false;
        let message = err.message
            ? err.message
            : "errore al caricamento dei dati utente";
        this.sendError({ message });
        this.$router.go(-1);
        if (err.statusCode === 401) {
          this.logout();
        }
      }

    },
    onAddCustomSeats() {
      if(!this.$refs.customForm.validate()) return;

      this.requestConfirm({title : 'Richiesta conferma', message:'Sei sicuro di aggiungere questo pacchetto?', callback: async ok => {

        if(!ok) return;
          this.loading = true;
          let data = { ...this.addSeatsCustom }
          if(data.expiration) {
            const d = DateTime.fromISO(data.expiration)
            data.expiration = d.toJSON()
          }

          data.user_id = this.$route.params.id;
          data.duration = utils.toInt(data.duration, 0)
          data.available = utils.toInt(data.available, 0)

          try {
            let res = await this.insert({collection:'userseats', data})
            this.sendSuccess({message:'Pacchetto inserito!'});
            await this.loadData()
            this.loading = false;
          } catch (err) {
            this.loading = false;
            let message = err.message
                ? err.message
                : "errore al caricamento dei dati utente";
            this.sendError({ message });
            this.$router.go(-1);
            if (err.statusCode === 401) {
              this.logout();
            }
          }

        }
      })
    },

  },
  async mounted() {
    await this.loadData()
    this.refreshCollection({collection:'kits'})
    this.refreshCollection({collection:'products'})
  }
}
</script>



<style scoped>

</style>