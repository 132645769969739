<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <h2 class="display-1 ml-4">{{ title }}</h2>
            <v-spacer />
            <v-btn text color="accent" @click="save(1)">Salva</v-btn>
            <v-btn text color="accent" @click="save(2)">Salva e continua</v-btn>
            <v-btn text color="accent" @click="save(3)">Salva e aggiungi</v-btn>
          </v-card-title>
          <v-card-text>
            <v-container fluid>
              <v-row>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-text-field
                          label="Titolo*"
                          rounded filled
                          v-model="editingItem.title.it"
                          :error="titleError"
                          :error-messages="requiredErrorMessages(titleError)"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-text-field
                      label="ID Prodotto (SKU)*"
                      rounded filled
                      v-model="editingItem.sku"
                      hint="Necessario per il sistema di vendita (paypal, stripe, ecc.)"
                      persistent-hint
                      :error="skuError"
                      :error-messages="requiredErrorMessages(skuError)"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-text-field
                      label="N° di sedute*"
                      rounded filled
                      v-model="editingItem.num_seats"
                      type="number"
                      step="1"
                      :error="seatsError"
                      :error-messages="requiredErrorMessages(seatsError)"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-text-field
                      label="Prezzo in euro*"
                      rounded filled
                      v-model="editingItem.price"
                      prefix="€"
                      type="number"
                      step="0.01"
                      :error="priceError"
                      :error-messages="requiredErrorMessages(priceError)"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-textarea
                          label="Descrizione*"
                          rounded filled
                          v-model="editingItem.description.it"
                          :error="descError"
                          :error-messages="requiredErrorMessages(descError)"
                  ></v-textarea>
                </v-col>

                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-switch
                      label="One-to-one"
                      rounded filled
                      v-model="editingItem.is_one_to_one"
                  ></v-switch>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-switch
                          label="Pubblicato"
                          rounded filled
                          v-model="editingItem.pub"
                  ></v-switch>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn text color="accent" @click="save(1)">Salva</v-btn>
            <v-btn text color="accent" @click="save(2)">Salva e continua</v-btn>
            <v-btn text color="accent" @click="save(3)">Salva e aggiungi</v-btn>
            <v-btn text color="accent" @click="save(4)">Salva e aggiungi prodotti</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import utils from '../utils';
  import { mapGetters, mapActions } from 'vuex';
  import config from "../config";
  import moment from 'moment'
  export default {
    name: "PacketForm",
    data () {
      return {
        editingItem : {
          title : { it : ''},
          description : { it : ''},
          num_seats : 0,
          price : 0.0,
          sku : '',
          is_one_to_one : false,
          pub : false,
        },
        titleError : false,
        descError : false,
        seatsError : false,
        priceError : false,
        skuError : false,
      }
    },
    computed : {
      ...mapGetters(['token', 'profile', 'is_root']),
      updating() {
        return !!this.editingItem._id
      },
      title() {
        return this.$route.params.id === 'new' ? 'Nuovo pacchetto sedute' : 'Modifica pacchetto sedute';
      },
      loading : {
        get() { return this.$store.state.loading; },
        set(val) { this.$store.state.loading = val; }
      },
    },
    methods : {
      ...mapActions(['sendSuccess', 'sendError', 'requestConfirm', 'logout', 'load', 'delete','get', 'update', 'insert','refreshCollection','upload']),
      requiredErrorMessages(err) {
        if(err) return ["Campo richiesto"];
        return []
      },
      loadData() {
        this.loading = true;
        let id = this.$route.params.id;
        this.get({collection : 'packets', id}).then((reply)=>{
          this.loading = false;
          if(reply.data) {
            this.editingItem = reply.data;
          }
        }).catch((err) => {
          this.loading = false;
          let message = err.message ? err.message : 'errore al caricamento dei dati del pacchetto sedute';
          this.sendError({message});
          this.$router.go(-1);
          if(err.statusCode === 401) {
            this.logout();
          }
        })
      },
      clearErrors() {
        this.titleError = false;
        this.descError = false;
        this.skuError = false;
        this.priceError = false;
        this.seatsError = false;
      },
      save( afterAction ) {
        this.clearErrors();
        let updating = this.updating;
        if(!this.editingItem.title.it) {
          this.titleError = true;
          return;
        }
        if(!this.editingItem.description.it) {
          this.descError = true;
          return;
        }
        if(!this.editingItem.sku) {
          this.skuError = true;
          return;
        }

        this.editingItem.num_seats = utils.toInt(this.editingItem.num_seats, 0)
        if(this.editingItem.num_seats <= 0) {
          this.seatsError = true;
          return;
        }
        this.editingItem.price = utils.toFloat(this.editingItem.num_seats, 0)
        if(this.editingItem.price < 0) {
          this.priceError = true;
          return;
        }


        let saveFn = updating ? this.update : this.insert;
        saveFn({collection:'packets', data : this.editingItem}).then((reply)=>{
          this.sendSuccess({ message : "Pacchetto sedute salvato"});
          switch (afterAction) {
            case 1:
              //go back
              this.$router.replace("/packets");
              break;
            case 2:
              //stay
              if(this.$route.params.id === 'new') {
                this.$router.replace("/packets/"+ reply.data._id);
              } else {
                this.editingItem = reply.data;
              }
              break;
            case 3:
              // new
              if(this.$route.params.id === 'new') {
                this.editingItem = {
                  title : { it : ''},
                  description : { it : ''},
                  num_seats : 0,
                  price : 0.0,
                  sku : '',
                  is_one_to_one : false,
                  pub : false,
                };
              } else {
                this.$router.replace("/packets/new");
              }
              break;
          }
        }).catch((err)=>{
          if(config.debug) console.log("Save packet error:", err);
          let message = err.message ? err.message : "errore al salvataggio del pacchetto sedute";
          this.sendError({ message });
          if(err.statusCode === 401) {
            this.$router.replace("/");
            this.logout();
          }
        })
      }
    },
    mounted() {
      if(this.$route.params.id !== 'new') {
        this.loadData()
      }
    }
  }
</script>

<style scoped></style>
