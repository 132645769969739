<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <h2 class="display-1 ml-4">Lista pagine statiche</h2>
            <v-spacer />
            <v-btn color="accent" text @click="$router.push('/cms/new')">Nuova</v-btn>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="6" md="4" lg="2">
                <v-text-field rounded solo v-model="titleSearch" label="Titolo" @input="applyFilter"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="2">
                <v-select rounded solo v-model="pubSearch" label="Pubblicato" @input="applyFilter" :items="pubOptions" clearable></v-select>
              </v-col>

            </v-row>
            <v-row>
              <v-col cols="12">
                <v-data-table :headers="headers" :items="items" :options.sync="pagination" :server-items-length="count" :loading="tableLoading"
                              no-data-text="Non ci sono pagine censite al momento."  :footer-props="footerProps">

                  <template v-slot:item.title.it="{ item }">
                    <a :href="'./#/cms/'+item._id" ><b v-if="item.title">{{ item.title.it }}</b></a>
                  </template>
                  <template v-slot:item.pub="{ item }">
                    <v-icon color="success" v-if="item.pub">mdi-check</v-icon>
                    <v-icon color="error" v-else>mdi-close</v-icon>
                  </template>
                  <template v-slot:item.edit="{ item }">
                    <v-btn color="accent" dark text icon v-on:click="$router.push({ name : 'cms form', params : { id : item._id }})">
                      <v-icon dark>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <template v-slot:item.remove="{ item }">
                    <v-btn color="error" dark text icon v-on:click="requestDelete(item._id)">
                      <v-icon dark>mdi-close</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import config from "../config";
import moment from 'moment'
import utils from '../utils'
export default {
  name: "CmsList",
  data () {
    return {
      footerProps : {
        "items-per-page-text" : "N° risultati per pagina",
        "items-per-page-options" : [10,20,50,100],
        "items-per-page" : 10
      },
      titleSearch : null,
      pubSearch : null,
      tableLoading : false,
      count : 0,
      items : [],
      pubOptions : [
        { text : "Pubblicato", value: "true" },
        { text : "Non pubblicato", value: "false" }
      ],
      headers : [
        { text : 'Titolo', sortable: true, align: 'left', value : 'title.it' },
        { text : 'Percorso', sortable: true, align: 'left', value : 'path' },
        { text : 'Pubblicata', sortable: true, align: 'center', value : 'pub', width : 60  },
        { text : 'Modifica', sortable: false, align: 'center', value : 'edit', width : 60 },
        { text : 'Rimuovi', sortable: false, align: 'center', value : 'remove', width : 60 },
      ],
      pagination: {},
      query : {}
    }
  },
  watch: {
    pagination: {
      handler () {
        this.refresh();
      },
      deep: true
    }
  },
  computed : {
    ...mapGetters(['profile'])
  },
  methods : {
    ...mapActions(['sendSuccess', 'sendError', 'requestConfirm', 'logout', 'load', 'delete']),
    applyFilter() {
      this.query.title = this.titleSearch || undefined
      this.query.pub = this.pubSearch || undefined
      this.refresh();
    },
    requestDelete( id ) {
      this.requestConfirm({ title : 'Richiesta cancellazione', message : 'Sei sicuro di voler eliminare definitivamente questa pagnina?', callback: ( accepted )=>{
          if(accepted) {
            this.delete({ collection : 'cms', id }).then(()=>{
              this.refresh();
              this.sendSuccess({message:'Pagina eliminata!'});
            }).catch((err)=>{
              let message = err.message ? err.message : 'errore alla cancellazione della pagina';
              this.sendError({ message });
              if(config.debug) console.error("Errore di cancellazione della pagina",err);
              if(err.statusCode === 401) {
                this.logout();
              }
            });
          }
        }});
    },
    refresh() {
      this.tableLoading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.pagination;
      let sort = null;
      let order = null;
      if(sortBy.length) {
        sort = sortBy[0];
        order = 'ASC';
        if(sortDesc[0]) order = 'DESC';
      }

      let filter = {
        query : this.query,
        limit : itemsPerPage,
        page, sort, order
      };

      this.load({ collection : 'cms', filter}).then((reply)=>{
        this.tableLoading = false;
        if(reply.data) {
          this.count = reply.count;
          this.items = reply.data;
        }
      }).catch((err)=>{
        this.tableLoading = false;
        this.sendError({message:"Errore al caricamento delle pagine."});
        if(config.debug) console.error("Errore al caricamento delle pagine: " + err);
        if(err.statusCode === 401) {
          this.logout();
        }
      })
    }
  },
  mounted() {
    this.refresh();
  }
}
</script>

<style scoped>

</style>