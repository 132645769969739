<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-card-title>
                        <h2 class="display-1 ml-4">Lista sezioni home</h2>
                        <v-spacer />
                        <v-btn color="accent" text @click="$router.push('/home/new')">Nuovo</v-btn>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12">
                                <v-data-table :headers="headers" :items="items"
                                              disable-pagination disable-sort hide-default-footer
                                              :server-items-length="count" :loading="tableLoading"
                                              no-data-text="Non ci sono sezioni censite al momento." >

                                    <template v-slot:item.name.it="{ item }">
                                        <a :href="'./#/home/'+item._id" ><b>{{ item.name.it }}</b></a>
                                    </template>
                                    <template v-slot:item.type="{ item }">
                                        <span v-if="item.type == 'custom'">Custom</span>
                                        <span v-if="item.type == 'genre'">Genere</span>
                                        <span v-if="item.type == 'resume'">Allenamenti in corso</span>
                                        <span v-if="item.type == 'mykits'">I miei pacchetti</span>
                                    </template>
                                    <template v-slot:item.edit="{ item }">
                                        <v-btn color="accent" dark text icon v-on:click="$router.push({ name : 'home form', params : { id : item._id }})">
                                            <v-icon dark>mdi-pencil</v-icon>
                                        </v-btn>
                                    </template>
                                    <template v-slot:item.remove="{ item }">
                                        <v-btn color="error" dark text icon v-on:click="requestDelete(item._id)">
                                            <v-icon dark>mdi-close</v-icon>
                                        </v-btn>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import config from "../config";
    import moment from 'moment'
    import utils from '../utils'
    export default {
        name: "GenreList",
        data () {
            return {
                tableLoading : false,
                count : 0,
                items : [],
                headers : [
                    { text : 'Ordinamento', sortable: false, align: 'center', value : 'order', width : 60 },
                    { text : 'Nome', sortable: false, align: 'left', value : 'name.it' },
                    { text : 'Tipologia', sortable: false, align: 'left', value : 'type' },
                    { text : 'Modifica', sortable: false, align: 'center', value : 'edit', width : 60 },
                    { text : 'Rimuovi', sortable: false, align: 'center', value : 'remove', width : 60 },
                ]
            }
        },
        computed : {
            ...mapGetters(['profile'])
        },
        methods : {
            ...mapActions(['sendSuccess', 'sendError', 'requestConfirm', 'logout', 'load', 'delete']),
            applyFilter() {
                this.query = {};
                this.refresh();

            },
            requestDelete( id ) {
                this.requestConfirm({ title : 'Richiesta cancellazione', message : 'Sei sicuro di voler eliminare definitivamente questa sezione?', callback: ( accepted )=>{
                        if(accepted) {
                            this.delete({ collection : 'home', id }).then(()=>{
                                this.refresh();
                                this.sendSuccess({message:'Sezione eliminata!'});
                            }).catch((err)=>{
                                let message = err.message ? err.message : 'errore alla cancellazione della sezione';
                                this.sendError({ message });
                                if(config.debug) console.error("Errore di cancellazione della sezione",err);
                                if(err.statusCode === 401) {
                                    this.logout();
                                }
                            })

                        }
                    }});
            },
            refresh() {
                this.tableLoading = true;
                let filter = {
                    query : this.query,
                    limit : 0,
                    page : 0, sort : null, order: null
                };

                this.load({ collection : 'home', filter}).then((reply)=>{
                    this.tableLoading = false;
                    if(reply.data) {
                        this.count = reply.count;
                        this.items = reply.data;
                    }
                }).catch((err)=>{
                    this.tableLoading = false;
                    this.sendError({message:"Errore al caricamento delle sezioni."});
                    if(config.debug) console.error("Errore al caricamento delle sezioni: " + err);
                    if(err.statusCode === 401) {
                        this.logout();
                    }
                })
            }
        },
        mounted() {
            this.refresh();
        }
    }
</script>

<style scoped>

</style>