<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <h2 class="display-1 ml-4">Report dell utente <a :href="'/users/'+item.user._id">{{item.user.name}} {{item.user.surname}}</a> del {{formatDate(item.date)}}</h2>
          </v-card-title>
          <v-card-text>
            <v-container fluid>
              <v-row>
                <v-col cols="12">
                  <ul>
                    <li class="body-1"><span class="font-weight-bold">Email utente:</span> <a :href="'mailto:'+item.user.email">{{item.kit.title.it}}</a></li>
                    <li class="body-1"><span class="font-weight-bold">Telefono utente:</span> {{item.user.phone}}</li>
                    <li class="body-1"><span class="font-weight-bold">Pacchetto:</span> <a :href="'/#/kits/'+item.kit._id">{{item.kit.title.it}}</a></li>
                    <li class="body-1"><span class="font-weight-bold">Prodotto:</span> <a :href="'/#/products/'+item.product._id">{{item.product.title.it}}</a></li>
                    <li class="body-1"><span class="font-weight-bold">Lezione:</span> <a :href="'/#/lessons/'+item.lesson._id">{{item.lesson.title.it}}</a></li>
                    <li class="body-1"><span class="font-weight-bold">N° allenamenti a settimana:</span> {{item.training}}</li>
                    <li class="body-1"><span class="font-weight-bold">Peso (kg):</span> {{item.weight}}</li>
                    <li class="body-1"><span class="font-weight-bold">Fatica percepita in questo allenamento (da 1 a 5):</span> {{item.borg}}</li>
                    <li class="body-1">
                      <span class="font-weight-bold">Rispetto agli allenamenti già svolti hai percepito molta fatica?</span>
                      {{item.perceived_fatigue ? "Sì" : "No"}}, <span v-if="item.perceived_fatigue_notes">Note: {{item.perceived_fatigue_notes}}</span>
                    </li>
                    <li class="body-1">
                      <span class="font-weight-bold">Hai percepito più fatica durante lo svolgimento delle prime lezioni rispetto alle ultime?</span>
                      {{item.perceived_fatigue_progress ? "Sì" : "No"}}, <span v-if="item.perceived_fatigue_progress_notes">Note: {{item.perceived_fatigue_progress_notes}}</span>
                    </li>
                    <li class="body-1">
                      <span class="font-weight-bold">Hai percepito più fatica durante lo svolgimento delle prime lezioni rispetto alle ultime?</span>
                      {{item.perceived_fatigue_progress ? "Sì" : "No"}}, <span v-if="item.perceived_fatigue_progress_notes">Note: {{item.perceived_fatigue_progress_notes}}</span>
                    </li>
                    <li class="body-1">
                      <span class="font-weight-bold">Hai percepito la stessa fatica durante tutte le lezioni?</span>
                      {{item.perceived_fatigue_all ? "Sì" : "No"}}, <span v-if="item.perceived_fatigue_all_notes">Note: {{item.perceived_fatigue_all_notes}}</span>
                    </li>
                    <li class="body-1">
                      <span class="font-weight-bold">Pensi di essere pronto a cambiare categoria e livello di allenamento?</span>
                      {{item.training_change_question ? "Sì" : "No"}}, <span v-if="item.training_change_question_notes">Note: {{item.training_change_question_notes}}</span>
                    </li>
                    <li class="body-1">
                      <span class="font-weight-bold">Ci sono esercizi che non hai capito?</span>
                      {{item.trainings_not_understood ? "Sì" : "No"}},
                      <span v-if="item.trainings_not_understood_list && item.trainings_not_understood_list.length">Quali?
                        <v-chip class="ma-1" v-for="ex in item.trainings_not_understood_list" :key="ex._id + '_not_understood'" @click="$router.push('/exercises/'+ex._id)">{{ex.title.it}}</v-chip>
                      </span>
                    </li>
                    <li class="body-1">
                      <span class="font-weight-bold">Ci sono esercizi che non riesci a svolgere?</span>
                      {{item.trainings_failed ? "Sì" : "No"}},
                      <span v-if="item.trainings_failed_list && item.trainings_failed_list.length">Quali?
                        <v-chip class="ma-1" v-for="ex in item.trainings_failed_list" :key="ex._id + '_failed'" @click="$router.push('/exercises/'+ex._id)">{{ex.title.it}}</v-chip>
                      </span>
                    </li>
                    <li class="body-1">
                      <span class="font-weight-bold">Riesci sempre a rimanere nei tempi dei singoli esercizi?</span>
                      {{item.training_in_time ? "Sì" : "No"}}, <span v-if="item.training_in_time_notes">Note: {{item.training_in_time_notes}}</span>
                    </li>
                    <li class="body-1">
                      <span class="font-weight-bold">Come ti senti da quando hai iniziato ad allenarti?</span>
                      <span v-if="item.feelings === 1">Male</span>
                      <span v-if="item.feelings === 2">Normale</span>
                      <span v-if="item.feelings === 3">Bene</span>
                    </li>
                    <li class="body-1">
                      <span class="font-weight-bold">Altre considerazioni</span> {{item.notes}}
                    </li>
                  </ul>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
export default {
  name: "CardView",
  data() {
    return {
      item : {
        user: { name: '', surname : '', email: '', phone : ''},
        kit: { title : { it: '' }},
        product: { title : { it: '' }},
        lesson: { title : { it: '' }},
        date : new Date(),
        weight : 0,
        training : 1,
        borg : 1,
        perceived_fatigue: false,
        perceived_fatigue_notes: "",
        perceived_fatigue_progress: false,
        perceived_fatigue_progress_notes: "",
        perceived_fatigue_all: false,
        perceived_fatigue_all_notes: false,
        training_change_question: false,
        training_change_question_notes: "",
        trainings_not_understood: false,
        trainings_not_understood_list : [],
        trainings_failed : false,
        trainings_failed_list : [],
        training_in_time : false,
        training_in_time_notes : "",
        feelings : 3,
        notes : "",
      },
    };
  },
  computed: {
    ...mapGetters(["token", "profile", "is_root"]),
    loading: {
      get() {
        return this.$store.state.loading;
      },
      set(val) {
        this.$store.state.loading = val;
      }
    },
  },
  methods: {
    ...mapActions([
      "sendSuccess",
      "sendError",
      "requestConfirm",
      "logout",
      "load",
      "delete",
      "get",
      "update",
      "insert"
    ]),
    formatDate( date ) {
      return moment(date).format("DD/MM/YYYY, HH:mm");
    },
    loadData() {
      this.loading = true;
      let id = this.$route.params.id;
      this.get({ collection: "reports", id: id + "?display=true" })
        .then(reply => {
          this.loading = false;
          if (reply.data) {
            this.item = reply.data;
          }
        })
        .catch(err => {
          this.loading = false;
          let message = err.message
            ? err.message
            : "Errore al caricamento dei dati del report!";
          this.sendError({ message });
          this.$router.go(-1);
          if (err.statusCode === 401) {
            this.logout();
          }
        });
    }
  },
  mounted() {
    this.loadData();
  }
};
</script>
