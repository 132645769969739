import utils from "../utils/index";
import {DateTime} from "luxon";

export default {
  password:  (v) => {
    if(!v) return true;
    const ok = /^(?=.*[A-Z])(?=.*[!?@#$&*.()^/\\|<>\-_:;,])(?=.*\d)(?=.*[a-z]).{8}$/.test(v)
    return ok || 'la password deve essere lunga almeno 8 caratteri e contenere maiuscole, minuscole, numeri e caratteri speciali'
  },
  minchars: (num) => {
    return value => {
      if (value && value.length < num) {
        return `minimo ${num} caratteri`;
      }
      return true;
    }
  },
  maxchars: (num) => {
    return value => {
      if (value && value.length > num) {
        return `massimo ${num} caratteri`;
      }
      return true;
    }
  },
  exactchars: (num) => {
    return value => {
      if (value && value.length !== num) {
        return `deve avere ${num} caratteri`;
      }
      return true;
    }
  },
  notEmpty: (v) => {
    if (v && v.length) return true;
    return 'campo obbligatorio'
  },
  path: (v) => {
    if (!v) return true;
    if(!v.startsWith("/")) return false
    if(v.indexOf("//") !== -1) return 'inserisci un percorso valido (es. "/cartella1/cartella2/")'
    if(v[v.length-1] !== '/') return 'inserisci un percorso valido (es. "/cartella1/cartella2/")'
    return true
  },
  filename: (v) => {
    if (!v) return true;
    if(v.indexOf("/") !== -1) return 'il filename non può contenere un percorso'
    if(v.indexOf(".") === -1) return 'il filename deve contenere un estensione'
    return true
  },
  required: (v) => !!v || 'campo obbligatorio',
  requiredIf: (v) => {
    return value => {
      if(!v && !value) return 'campo obbligatorio';
      return true;
    }
  },
  confirmPassword: (v) => {
    return value => {
      if(!v && !value) return true
      if (value !== v) {
        return `la password e la conferma password devono essere uguali`;
      }
      return true;
    }
  },
  slug: (v) => /^[a-z0-9]+(?:-[a-z0-9]+)*$/.test(v) || 'puoi inserire solo lettere minuscole, numeri e il carattere "-"',
  pIva: (v) => /^[0-9]{11}$/.test(v) || 'inserisci una partita iva valida',
  codFiscale: (v) => /^[A-Za-z]{6}[0-9]{2}[A-Za-z]{1}[0-9]{2}[A-Za-z]{1}[0-9]{3}[A-Za-z]{1}$/.test(v) || 'inserisci un codice fiscale valido',
  sdl: (v) => !v || /^[A-Z0-9]{6,7}$/.test(v) || 'inserisci un codice destinatario valido',
  mobilePhoneItaly: (v) => /^3[0-9]{8,9}$/.test(v) || 'inserisci un numero mobile valido',
  minInt: (min) => {
    return value => {
      if (value) {
        const val = utils.toInt(value, min - 1)
        if (val < min) return `deve essere un numero intero maggiore o uguale a ${min}`;
      }
      return true;
    }
  },
  maxInt: (max) => {
    return value => {
      if (value) {
        const val = utils.toInt(value, max + 1)
        if (val > max) return `deve essere un numero intero minore o uguale a ${max}`;
      }
      return true;
    }
  },

  minFloat: (min) => {
    return value => {
      if (value) {
        const val = utils.toFloat(value, min - 1)
        if (val < min) return `deve essere un numero maggiore o uguale a ${min}`;
      }
      return true;
    }
  },
  maxFloat: (max) => {
    return value => {
      if (value) {
        const val = utils.toFloat(value, max + 1)
        if (val > max) return `deve essere un numero intero minore o uguale a ${max}`;
      }
      return true;
    }
  },
  date: (value) => {
    if (value) {
      const val = DateTime.fromISO(value)
      if (!val.isValid) return `inserisci una data valida`;
    }
    return true;
  },
  link: (value) => {
    if (value) {
      let url;
      if(typeof value === 'string' || value instanceof String) {
        url = value
      } else if(value.url) {
        url = value.url
      }
      if(url && !url.startsWith('https://') && !url.startsWith('http://')) return "inserisci una URL valida";
    }
    return true;
  }

}
