import Vue from 'vue'
import Vuex from 'vuex'
import api from '../api'
import utils from "../utils";
import {seatsTypesOptions} from "../consts";

Vue.use(Vuex);
const state = {
  token : sessionStorage.token || null,
  loading : false,
  loadingMessage : 'Loading...',
  loginDialog : false,
  profile : null,
  dialogMessage : '',
  successDialog : false,
  warningDialog : false,
  errorDialog : false,
  infoDialog : false,
  confirmDialog : false,
  confirmDialogTitle: 'Richiesta conferma',
  confirmDialogMessage : 'Sei sicuro?',
  confirmDialogCallback : null,
  media : [],
  difficulties : [],
  categories : [],
  genre : [],
  exercises : [],
  users : [],
  kits : [],
  products : [],
  purchases : [],
  notifications : [],
  packets : [],
  bookingprefs : [],
  subcategories : [],
  userseats : [],
  cards : [],
  pauses : [],
  settings : {},
};

const RoleVal = {
  root : 1000,
  admin: 800,
  editor : 500,
  viewer : 200,
  none :0
}

const roleVal = (role) => {
  switch (role) {
    case "root":
      return RoleVal.root;
    case "admin":
      return RoleVal.admin;
    case "editor":
      return RoleVal.editor;
    case "viewer":
      return RoleVal.viewer;
  }
  return RoleVal.none;
}
const getters = {
  userId : (state) => state.profile._id,
  token : (state) => state.token,
  profile : (state) => state.profile,
  confirmDialogMessage : (state) => state.confirmDialogMessage,
  is_viewer : (state) => state.profile && roleVal(state.profile.role) >= RoleVal.viewer,
  is_editor : (state) => state.profile && roleVal(state.profile.role) >= RoleVal.editor,
  is_admin : (state) => state.profile && roleVal(state.profile.role) >= RoleVal.admin,
  is_root : (state) => state.profile && roleVal(state.profile.role) >= RoleVal.root,
  media : (state) => state.media,
  difficulties : (state) => state.difficulties,
  categories : (state) => state.categories,
  genre : (state) => state.genre,
  exercises : () => state.exercises,
  users : (state) => state.users,
  cards : (state) => state.cards,
  kits : (state) => state.kits,
  products : (state) => state.products,
  purchases : (state) => state.purchases,
  notifications : (state) => state.notifications,
  packets : (state) => state.packets,
  bookingprefs : (state) => state.bookingprefs,
  subcategories : (state) => state.subcategories,
  userseats : (state) => state.userseats,
  settings : (state) => state.settings,
  pauses : (state) => state.pauses,
};

const mutations = {
  logout : (state) => {
    state.token = null;
    state.profile = null;
    state.loginDialog = true;
    sessionStorage.removeItem('token');
  },
  token : (state, { token }) => {
    state.token = token;
    if(token)
      sessionStorage.setItem("token" , token);
    else
      sessionStorage.removeItem("token");
  },
  profile : (state, { profile }) => {
    console.log(profile);
    state.profile = profile;
  },
  message : (state, { type, message }) => {
    switch (type) {
      case 'success':
        state.successDialog = true;
        state.dialogMessage = message;
        break;
      case 'warning':
        state.warningDialog = true;
        state.dialogMessage = message;
        break;
      case 'error':
        state.errorDialog = true;
        state.dialogMessage = message;
        break;
      case 'info':
      default:
        state.infoDialog = true;
        state.dialogMessage = message;
        break;
    }
  },
  confirm : (state, { title, message, callback }) => {
    state.confirmDialogTitle = title || 'Richiesta conferma';
    state.confirmDialogMessage = message || 'Sei sicuro?';
    state.confirmDialogCallback = callback || null;
    state.confirmDialog = true;
  },
  media  : (state, { data }) => {
    state.media = data;
  },
  categories  : (state, { data }) => {
    state.categories = data;
  },
  genre  : (state, { data }) => {
    state.genre = data;
  },
  difficulties  : (state, { data }) => {
    state.difficulties = data;
  },
  exercises  : (state, { data }) => {
    state.exercises = data;
  },
  users  : (state, { data }) => {
    state.users = data;
  },
  kits  : (state, { data }) => {
    state.kits = data;
  },
  products  : (state, { data }) => {
    state.products = data;
  },
  purchases  : (state, { data }) => {
    state.purchases = data;
  },
  notifications  : (state, { data }) => {
    state.notifications = data || [];
  },
  packets  : (state, { data }) => {
    state.packets = data || [];
  },
  bookingprefs  : (state, { data }) => {
    state.bookingprefs = data || [];
  },
  subcategories  : (state, { data }) => {
    state.subcategories = data || [];
  },
  cards  : (state, { data }) => {
    state.cards = data || [];
  },
  userseats  : (state, { data }) => {
    state.userseats = data || [];
  },
  pauses  : (state, { data }) => {
    state.pauses = data || [];
  },
  settings  : (state, { data }) => {
    state.settings = data
  },
};

const actions = {
  login : ({commit}, { email, password}) => {
    return api.login( email, password );
  },
  loadSettings({commit}) {
    return new Promise((resolve, reject) => {
      api.restGet(state.token, 'settings').then((reply) => {
        if(reply.success) {
          commit('settings', { data : reply.data });
          resolve();
          return
        }
        commit('message', { type : 'error', message : 'Non hai i permessi per accedere a questo pannello'});
        reject();
      }).catch(reject)
    })
  },
  loadProfile({commit}) {
    return new Promise((resolve, reject) => {
      api.profile(state.token).then((reply) => {
        if(reply.success) {
          commit('profile', { profile : reply.data });
          resolve();
          return
        }
        commit('message', { type : 'error', message : 'Non hai i permessi per accedere a questo pannello'});
        reject();
      }).catch(reject)
    })
  },
  refreshToken({commit}) {
    api.heartbeat(state.token).then((reply) => {
      if(reply && reply.token) {
        commit('token', { token: reply.token })
        commit('profile', { profile: reply.user })
      }

    }).catch((err) => {
      console.log("refresh token error:", err);
    })
  },
  loadNotifications({commit}) {
    api.restLoad(state.token, "notifications", {}, 0, 1, null, null).then((reply) => {
      if(reply.success) {
        commit('notifications', { data : reply.data });
      }
    }).catch(console.error);
  },
  setNotificationRead({commit},{ id }) {
    api.restGet(state.token, "notifications", id).then(() => {}).catch(console.log);
  },
  logout : ({ commit }) => {
    commit('logout')
  },
  setToken : ({ commit }, { token }) => {
    commit('token', { token })
  },
  setProfile : ({ commit }, { profile }) => {
    commit('profile', { profile })
  },
  sendSuccess({ commit }, { message }) {
    commit('message', { type : 'success', message })
  },
  sendWarning({ commit }, { message }) {
    commit('message', { type : 'warning', message })
  },
  sendError({ commit }, { message }) {
    commit('message', { type : 'error', message })
  },
  sendInfo({ commit }, { message }) {
    commit('message', { type : 'info', message })
  },
  requestConfirm({commit}, { title, message, callback }) {
    commit('confirm', { title, message, callback })
  },
  load({commit}, { collection, filter }) {
    let { query, limit, page, sort, order } = filter;
    return api.restLoad( state.token, collection, query, limit, page, sort, order);
  },
  get({commit}, { collection, id }) {
    return api.restGet( state.token, collection, id);
  },
  insert({commit}, { collection, data }) {
    return api.restInsert( state.token, collection, data );
  },
  update({commit}, { collection, data, noId }) {
    return api.restUpdate( state.token, collection, data, noId );
  },
  delete({commit}, { collection, id }) {
    return api.restDelete( state.token, collection, id);
  },
  upload({commit}, { where, formData }) {
    return api.upload( state.token, where, formData);
  },
  refreshCollection({ commit }, { collection }) {
    api.restLoad( state.token, collection, {}, 0, 1, "", "asc").then(( reply )=>{
      if(reply.data) {
        commit(collection, { data : reply.data });
      }
    }).catch((err) => { console.log(err) })
  },
  async refreshCollectionAsync({ commit }, { collection }) {
    try  {
      const reply = await api.restLoad( state.token, collection, {}, 0, 1, "", "asc")
      if(reply.data) {
        commit(collection, { data : reply.data });
      }
    } catch (e) {
      console.log(e)
    }

  },
};

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  modules: {}
})
