<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-card-title>
                        <h2 class="display-1 ml-4">Lista utenti</h2>
                        <v-spacer />
                        <v-btn color="accent" text @click="$router.push('/users/new')">Nuovo</v-btn>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="6" md="4" lg="2">
                                <v-text-field rounded solo v-model="nameSearch" label="Nome" @input="applyFilter"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4" lg="2">
                                <v-text-field rounded solo v-model="emailSearch" label="Email" @input="applyFilter"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4" lg="2">
                                <v-select rounded solo v-model="roleSearch" label="Ruolo" @input="applyFilter" :items="roleOptions" clearable></v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-data-table :headers="headers" :items="items" :options.sync="pagination" :server-items-length="count" :loading="tableLoading"
                                              no-data-text="Non ci sono utenti censiti al momento."  :footer-props="footerProps">

                                    <template v-slot:item.surname="{ item }">
                                        <a :href="'./#/users/'+item._id" ><b>{{ item.surname }}</b>,  {{item.name}}</a>
                                    </template>
                                    <template v-slot:item.date_of_birth="{ item }">
                                        {{ calcAge(item.date_of_birth) }}
                                    </template>
                                  <template v-slot:item.seats="{ item }">
                                    <v-btn color="accent" dark text icon v-on:click="$router.push(`/users/${item._id}/seats`)">
                                      <v-icon dark>mdi-seat</v-icon>
                                    </v-btn>
                                  </template>
                                    <template v-slot:item.edit="{ item }">
                                        <v-btn color="accent" dark text icon v-on:click="$router.push({ name : 'user form', params : { id : item._id }})">
                                            <v-icon dark>mdi-pencil</v-icon>
                                        </v-btn>
                                    </template>
                                    <template v-slot:item.remove="{ item }">
                                        <v-btn color="error" dark text icon v-on:click="requestDelete(item._id)">
                                            <v-icon dark>mdi-close</v-icon>
                                        </v-btn>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>

            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import config from "../config";
    import moment from 'moment'
    import utils from '../utils'
    export default {
        name: "UserList",
        data () {
            return {
                footerProps : {
                    "items-per-page-text" : "N° risultati per pagina",
                    "items-per-page-options" : [10,20,50,100],
                    "items-per-page" : 10
                },
                nameSearch : null,
                emailSearch : null,
                roleSearch : null,
                tableLoading : false,
                count : 0,
                items : [],
                headers : [
                    { text : 'Cognome e nome', sortable: true, align: 'left', value : 'surname' },
                    { text : 'Email', sortable: true, align: 'left', value : 'email' },
                    { text : 'Telefono', sortable: true, align: 'left', value : 'phone' },
                    { text : 'Età', sortable: true, align: 'left', value : 'date_of_birth' },
                    { text : 'Ruolo', sortable: true, align: 'left', value : 'role' },
                    { text : 'Sedute', sortable: false, align: 'center', value : 'seats', width : 60 },
                    { text : 'Modifica', sortable: false, align: 'center', value : 'edit', width : 60 },
                    { text : 'Rimuovi', sortable: false, align: 'center', value : 'remove', width : 60 },
                ],
                roleOptions : config.roles,
                pagination: {},
                query : {}
            }
        },
        watch: {
            pagination: {
                handler () {
                    this.refresh();
                },
                deep: true
            }
        },
        computed : {
            ...mapGetters(['profile'])
        },
        methods : {
            ...mapActions(['sendSuccess', 'sendError', 'requestConfirm', 'logout', 'load', 'delete']),
            calcAge( birth ) {
                let age = utils.calcAge(birth);
                return age + " anni";
            },
            applyFilter() {
                this.query = {};
                this.query.fullname = this.nameSearch ? this.nameSearch : undefined;
                this.query.email = this.emailSearch ? this.emailSearch : undefined;
                this.query.role = this.roleSearch ? this.roleSearch : undefined;
                this.refresh();
            },
            requestDelete( id ) {
                this.requestConfirm({ title : 'Richiesta cancellazione', message : 'Sei sicuro di voler eliminare definitivamente questo utente? Eliminerai anche tutti i suoi acquisti (se presenti).', callback: ( accepted )=>{
                        if(accepted) {
                            this.delete({ collection : 'users', id }).then(()=>{
                                this.refresh();
                                this.sendSuccess({message:'Utente eliminato!'});
                            }).catch((err)=>{
                                let message = err.message ? err.message : 'errore alla cancellazione dell\'utente';
                                this.sendError({ message });
                                if(config.debug) console.error("Errore di cancellazione dell'utente",err);
                                if(err.statusCode === 401) {
                                    this.logout();
                                }
                            })

                        }
                    }});
            },
            refresh() {
                this.tableLoading = true;
                const { sortBy, sortDesc, page, itemsPerPage } = this.pagination;
                let sort = null;
                let order = null;
                if(sortBy.length) {
                    sort = sortBy[0];
                    order = 'ASC';
                    if(sortDesc[0]) order = 'DESC';
                }

                let filter = {
                    query : this.query,
                    limit : itemsPerPage,
                    page, sort, order
                };

                this.load({ collection : 'users', filter}).then((reply)=>{
                    this.tableLoading = false;
                    if(reply.data) {
                        this.count = reply.count;
                        this.items = reply.data;
                    }
                }).catch((err)=>{
                    this.tableLoading = false;
                    this.sendError({message:"Errore al caricamento degli utenti."});
                    if(config.debug) console.error("Errore al caricamento degli utenti: " + err);
                    if(err.statusCode === 401) {
                        this.logout();
                    }
                })
            }
        },
        mounted() {
            this.refresh();
        }
    }
</script>

<style scoped>

</style>