<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-card-title>
                        <h2 class="display-1 ml-4">Lista lezioni di {{product.title.it}}</h2>
                        <v-spacer />
                        <v-btn color="accent" text @click="$router.push('/kits/'+$route.params.kitId+'/products/' + product._id + '/lessons/new')">Nuova</v-btn>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12">
                                <v-data-table :headers="headers" :items="items" :options.sync="pagination" :server-items-length="count" :loading="tableLoading"
                                              no-data-text="Non ci sono lezioni censite al momento."  :footer-props="footerProps">

                                    <template v-slot:item.title.it="{ item }">
                                        <a :href="'/#/kits/'+$route.params.kitId+'/products/' + product._id + '/lessons/'+item._id" ><b>{{ item.title.it }}</b></a>
                                    </template>

                                    <template v-slot:item.schedules="{ item }">
                                        {{ item.schedules.length }} sessioni di esercizio
                                    </template>
                                    <template v-slot:item.edit="{ item }">
                                        <v-btn color="accent" dark text icon v-on:click="$router.push({ name : 'lesson form', params : { productId: product._id, id : item._id }})">
                                            <v-icon dark>mdi-pencil</v-icon>
                                        </v-btn>
                                    </template>
                                    <template v-slot:item.remove="{ item }">
                                        <v-btn color="error" dark text icon v-on:click="requestDelete(item._id)">
                                            <v-icon dark>mdi-close</v-icon>
                                        </v-btn>
                                    </template>
                                    <template v-slot:item.clone="{ item }">
                                        <v-btn color="accent" dark text icon v-on:click="requestClone(item._id)">
                                            <v-icon dark>mdi-content-copy</v-icon>
                                        </v-btn>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>

            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import config from "../config";
    import moment from 'moment'
    import utils from '../utils'
    export default {
        name: "LessonsList",
        data () {
            return {
                product : {
                    _id : null,
                    title : {
                        it : ''
                    }
                },
                footerProps : {
                    "items-per-page-text" : "N° risultati per pagina",
                    "items-per-page-options" : [10,20,50,100],
                    "items-per-page" : 10
                },
                tableLoading : false,
                count : 0,
                items : [],
                headers : [
                    { text : 'Titolo', sortable: true, align: 'left', value : 'title.it' },
                    { text : 'Sessioni', sortable: true, align: 'left', value : 'schedules' },
                    { text : 'Clona', sortable: false, align: 'center', value : 'clone', width : 60 },
                    { text : 'Modifica', sortable: false, align: 'center', value : 'edit', width : 60 },
                    { text : 'Rimuovi', sortable: false, align: 'center', value : 'remove', width : 60 },
                ],
                roleOptions : config.roles,
                pagination: {},
                query : {}
            }
        },
        watch: {
            pagination: {
                handler () {
                    this.refresh();
                },
                deep: true
            }
        },
        computed : {
            ...mapGetters(['profile'])
        },
        methods : {
            ...mapActions(['sendSuccess', 'sendError', 'requestConfirm', 'logout', 'load', 'delete','get']),
            applyFilter() {
                this.query = {};
                this.refresh();
            },
            requestDelete( id ) {
                this.requestConfirm({ title : 'Richiesta cancellazione', message : 'Sei sicuro di voler eliminare definitivamente questa lezione?', callback: ( accepted )=>{
                        if(accepted) {
                            this.delete({ collection : 'lessons', id }).then(()=>{
                                this.refresh();
                                this.sendSuccess({message:'Lezione eliminata!'});
                            }).catch((err)=>{
                                let message = err.message ? err.message : 'errore alla cancellazione della lezione';
                                this.sendError({ message });
                                if(config.debug) console.error("Errore di cancellazione della lezione",err);
                                if(err.statusCode === 401) {
                                    this.logout();
                                }
                            })

                        }
                    }});
            },
            requestClone( id ) {
                this.requestConfirm({ title : 'Richiesta clonazione', message : 'Sei sicuro di voler duplicare questo elemento?', callback: ( accepted )=>{
                        if(accepted) {
                            this.get({ collection : 'lessons', id : id + "/duplicate" }).then(()=>{
                                this.refresh();
                                this.sendSuccess({message:'Elemento duplicato!'});
                            }).catch((err)=>{
                                let message = err.message ? err.message : "errore alla duplicazione dell'elemento";
                                this.sendError({ message });
                                if(config.debug) console.error("Errore di duplicazione dell'elemento",err);
                                if(err.statusCode === 401) {
                                    this.logout();
                                }
                            })

                        }
                    }});
            },
            loadProduct() {
                this.get({collection:"products", id: this.$route.params.id}).then((reply) => {
                    if(!reply.data || reply.data.kit_id !== this.$route.params.kitId) {
                        this.$router.go(-1);
                        return
                    }
                    this.product = reply.data;
                }).catch((err)=>{
                    console.log(err);
                    this.$router.go(-1);
                });
            },
            refresh() {
                this.tableLoading = true;
                const { sortBy, sortDesc, page, itemsPerPage } = this.pagination;
                let sort = null;
                let order = null;
                if(sortBy.length) {
                    sort = sortBy[0];
                    order = 'ASC';
                    if(sortDesc[0]) order = 'DESC';
                }

                let filter = {
                    query : this.query,
                    limit : itemsPerPage,
                    page, sort, order
                };

                this.load({ collection : 'products/'+this.$route.params.id+'/lessons', filter}).then((reply)=>{
                    this.tableLoading = false;
                    if(reply.data) {
                        this.count = reply.count;
                        this.items = reply.data;
                    }
                }).catch((err)=>{
                    this.tableLoading = false;
                    this.sendError({message:"Errore al caricamento delle lezioni."});
                    if(config.debug) console.error("Errore al caricamento delle lezioni: " + err);
                    if(err.statusCode === 401) {
                        this.logout();
                    }
                })
            }
        },
        mounted() {
            this.product._id = this.$route.params.id;
            //this.editingItem.product_id = this.$route.params.id;
            this.loadProduct();
            this.refresh();
        }
    }
</script>

<style scoped>

</style>