<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <h2 class="display-1 ml-4">{{ title }}</h2>
            <v-spacer />
            <v-btn text color="accent" @click="save(1)">Salva</v-btn>
            <v-btn text color="accent" @click="save(2)">Salva e continua</v-btn>
            <v-btn text color="accent" @click="save(3)">Salva e aggiungi</v-btn>
          </v-card-title>
          <v-card-text>
            <v-container fluid>
              <v-row>
                <v-col cols="12" lg="6">
                  <v-text-field
                      label="Nome*"
                      rounded filled
                      v-model="editingItem.name.it"
                      :error="nameError"
                      :error-messages="requiredErrorMessages(nameError)"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6" lg="4" xl="3" v-if="editingItem.cover">
                  <v-img :src="editingItem.cover" />
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-file-input
                      label="Copertina"
                      rounded filled
                      v-model="file"
                      accept=".png, .jpg, .jpeg"
                  ></v-file-input>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn text color="accent" @click="save(1)">Salva</v-btn>
            <v-btn text color="accent" @click="save(2)">Salva e continua</v-btn>
            <v-btn text color="accent" @click="save(3)">Salva e aggiungi</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import utils from '../utils';
import { mapGetters, mapActions } from 'vuex';
import config from "../config";
import moment from 'moment'
export default {
  name: "CategoryForm",
  data () {
    return {
      file : null,
      editingItem : {
        name : { it : ''},
        cover : '',
      },
      nameError : false,
    }
  },
  computed : {
    ...mapGetters(['token', 'profile', 'is_root']),
    updating() {
      return !!this.editingItem._id
    },
    title() {
      return this.$route.params.id === 'new' ? 'Nuova sottocategoria prodotto' : 'Modifica sottocategoria prodotto';
    },
    loading : {
      get() { return this.$store.state.loading; },
      set(val) { this.$store.state.loading = val; }
    }
  },
  methods : {
    ...mapActions(['sendSuccess', 'sendError', 'requestConfirm', 'logout', 'load', 'delete','get', 'update', 'upload', 'insert']),
    requiredErrorMessages(err) {
      if(err) return ["Campo richiesto"];
      return []
    },
    loadData() {
      this.loading = true;
      let id = this.$route.params.id;
      this.get({collection : 'subcategories', id}).then((reply)=>{
        this.loading = false;
        if(reply.data) {
          this.editingItem = reply.data;
        }
      }).catch((err) => {
        this.loading = false;
        let message = err.message ? err.message : 'errore al caricamento dei dati della sottocategoria';
        this.sendError({message});
        this.$router.go(-1);
        if(err.statusCode === 401) {
          this.logout();
        }
      })
    },
    clearErrors() {
      this.nameError = false;
      this.abbrevError = false;
    },
    async save( afterAction ) {
      this.clearErrors();
      let updating = this.updating;


      if(!this.editingItem.name.it) {
        this.nameError = true;
        return;
      }

      let saveFn = updating ? this.update : this.insert;
      try {
        let reply = await saveFn({collection:'subcategories', data : this.editingItem});
        if(reply.data && this.file) {
          let formData = new FormData();
          formData.append('file', this.file);
          reply = await this.upload({where: `subcategories/${reply.data._id}/cover`, formData})
        }


        this.sendSuccess({ message : "Sottocategoria prodotto salvata"});
        this.file = null;
        switch (afterAction) {
          case 1:
            //go back
            await this.$router.replace("/subcategories");
            break;
          case 2:
            //stay
            if(this.$route.params.id === 'new') {
              await this.$router.replace("/subcategories/"+ reply.data._id);
            } else {
              this.editingItem = reply.data;
            }
            break;
          case 3:
            // new
            if(this.$route.params.id === 'new') {
              this.editingItem = {
                name : { it : ''},
                cover : '',
              };
            } else {
              await this.$router.replace("/subcategories/new");
            }
            break;
        }
      } catch (err) {
        if(config.debug) console.log("Save subcategory error:", err);
        let message = err.message ? err.message : "Errore al salvataggio della sottocategoria prodotto";
        this.sendError({ message });
        if(err.statusCode === 401) {
          this.$router.replace("/");
          this.logout();
        }
      }
    }
  },
  mounted() {
    if(this.$route.params.id !== 'new') {
      this.loadData()
    }
  }
}
</script>

<style scoped></style>
