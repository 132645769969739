<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <h2 class="display-1 ml-4">Lista promozioni</h2>
            <v-spacer />
            <v-btn color="accent" text @click="$router.push('/promo/new')">Nuova</v-btn>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="6" md="4" lg="2">
                <v-text-field rounded solo v-model="titleSearch" label="Titolo" @input="applyFilter"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="2">
                <v-autocomplete rounded solo v-model="userSearch" label="Utente" :items="userOptions" @input="applyFilter"></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="2">
                <v-autocomplete rounded solo v-model="packetSearch" label="Pacchetto sedute" :items="packetOptions" @input="applyFilter"></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="2">
                <v-switch rounded solo v-model="notConsumedSearch" label="Non utilizzato" @input="applyFilter" ></v-switch>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-data-table :headers="headers" :items="items" :options.sync="pagination" :server-items-length="count" :loading="tableLoading"
                              no-data-text="Non ci sono promozioni censite al momento."  :footer-props="footerProps">

                  <template v-slot:item.title.it="{ item }">
                    <a :href="'./#/promo/'+item._id" ><b>{{ item.title }}</b></a>
                  </template>

                  <template v-slot:item.consumed="{ item }">
                    <v-icon color="success" v-if="item.pub">mdi-check</v-icon>
                    <v-icon color="error" v-else>mdi-close</v-icon>
                  </template>

                  <template v-slot:item.discount_type="{ item }">
                    <span v-if="item.discount_type === 'on_unit'">Sconto per seduta</span>
                    <span v-else-if="item.discount_type === 'on_total'">Sconto sul totale</span>
                    <span v-else>{{item.discount_type}}</span>
                  </template>

                  <template v-slot:item.value="{ item }">
                    <b>{{item.value.toFixed(2)}}<span v-if="item.is_percent_discount">%</span><span v-else>€</span></b>
                  </template>
                  <template v-slot:item.user.surname="{ item }">
                    <a :href="'./#/users/'+item.user._id" >{{ item.user.surname }} {{ item.user.name }}</a>
                  </template>
                  <template v-slot:item.packet.title.it="{ item }">
                    <a :href="'./#/packets/'+item.packet._id" >{{ item.packet.title.it }}</a>
                  </template>

                  <template v-slot:item.edit="{ item }">
                    <v-btn color="accent" v-if="!item.consumed" dark text icon v-on:click="$router.push({ name : 'promo form', params : { id : item._id }})">
                      <v-icon dark>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <template v-slot:item.remove="{ item }">
                    <v-btn color="error" dark text icon v-on:click="requestDelete(item._id)">
                      <v-icon dark>mdi-close</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import config from "../config";
import utils from '../utils'
export default {
  name: "PacketList",
  data () {
    return {
      footerProps : {
        "items-per-page-text" : "N° risultati per pagina",
        "items-per-page-options" : [10,20,50,100],
        "items-per-page" : 10
      },
      titleSearch : null,
      packetSearch : null,
      userSearch : null,
      notConsumedSearch : false,
      tableLoading : false,
      count : 0,
      items : [],
      headers : [
        { text : 'Titolo', sortable: true, align: 'left', value : 'title' },
        { text : 'Codice', sortable: false, align: 'left', value : 'code' },
        { text : 'Utente', sortable: true, align: 'left', value : 'user.surname' },
        { text : 'Sedute', sortable: true, align: 'left', value : 'packet.title.it' },
        { text : 'Tipo', sortable: true, align: 'left', value : 'discount_type' },
        { text : 'Valore', sortable: true, align: 'left', value : 'value' },
        { text : 'Utilizzato', sortable: true, align: 'center', value : 'consumed' },
        { text : 'Modifica', sortable: false, align: 'center', value : 'edit', width : 60 },
        { text : 'Rimuovi', sortable: false, align: 'center', value : 'remove', width : 60 },
      ],
      pagination: {},
      query : {}
    }
  },
  watch: {
    pagination: {
      handler () {
        this.refresh();
      },
      deep: true
    }
  },
  computed : {
    ...mapGetters(['profile','users','packets']),
    userOptions() {
      return this.users.map((item)=>{
        return { text : item.surname + ' ' + item.name, value: item._id}
      })
    },
    packetOptions() {
      return this.packets.map((item)=>{
        return { text : item.title ?  + item.title.it : item._id, value: item._id }
      })
    }
  },
  methods : {
    ...mapActions(['sendSuccess', 'sendError', 'requestConfirm', 'logout', 'load', 'get', 'delete','refreshCollection']),
    applyFilter() {
      this.query = {};
      this.query.title = this.titleSearch ? this.titleSearch : undefined;
      this.query.user = this.userSearch ? this.userSearch : undefined;
      this.query.packet = this.packetSearch ? this.packetSearch : undefined;
      this.query.consumed = this.notConsumedSearch ? true : undefined;
      this.refresh();
    },
    requestDelete( id ) {
      this.requestConfirm({ title : 'Richiesta cancellazione', message : 'Sei sicuro di voler eliminare definitivamente questa promozione?', callback: ( accepted )=>{
          if(accepted) {
            this.delete({ collection : 'promo', id }).then(()=>{
              this.refresh();
              this.sendSuccess({message:'Promozione eliminata!'});
            }).catch((err)=>{
              let message = err.message ? err.message : 'errore alla cancellazione della promozione';
              this.sendError({ message });
              if(config.debug) console.error("Errore di cancellazione della promozione",err);
              if(err.statusCode === 401) {
                this.logout();
              }
            })
          }
        }});
    },
    refresh() {
      this.tableLoading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.pagination;
      let sort = null;
      let order = null;
      if(sortBy.length) {
        sort = sortBy[0];
        order = 'ASC';
        if(sortDesc[0]) order = 'DESC';
      }

      let filter = {
        query : this.query,
        limit : itemsPerPage,
        page, sort, order
      };

      this.load({ collection : 'promo', filter}).then((reply)=>{
        this.tableLoading = false;
        if(reply.data) {
          this.count = reply.count;
          this.items = reply.data;
        }
      }).catch((err)=>{
        this.tableLoading = false;
        this.sendError({message:"Errore al caricamento delle promozioni."});
        if(config.debug) console.error("Errore al caricamento delle promozioni: " + err);
        if(err.statusCode === 401) {
          this.logout();
        }
      })
    }
  },
  mounted() {
    this.refreshCollection({collection:'users'});
    this.refreshCollection({collection:'packets'});
    this.refresh();
  }
}
</script>

<style scoped>

</style>