<template>
  <v-text-field filled rounded :label="label" clearable v-model="modelValue" type="date" dense hide-details></v-text-field>
</template>

<script>

import moment from "moment";

export default {
  name: "DateField",
  props : {
    label : String,
    value : String,
  },
  computed : {
    modelValue : {
      get() {
        return this.value ? moment(this.value).format('YYYY-MM-DD') : null
      },
      set(val) {
        if(!val) {
          this.$emit('input', null)
          return
        }
        let d = moment(val)
        this.$emit('input', d.toJSON())
      }
    }
  }
}
</script>



<style scoped>

</style>