<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-card-title>
                        <h2 class="display-1 ml-4">Lista pacchetti</h2>
                        <v-spacer />
                        <v-btn color="accent" text @click="$router.push('/kits/new')">Nuovo</v-btn>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="6" md="4" lg="2">
                                <v-text-field rounded solo v-model="titleSearch" label="Titolo" @input="applyFilter"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4" lg="2">
                                <v-select rounded solo v-model="categorySearch" label="Categoria" @input="applyFilter" :items="categoryOptions" clearable></v-select>
                            </v-col>
                            <v-col cols="12" sm="6" md="4" lg="2">
                                <v-select rounded solo v-model="genreSearch" label="Genere" @input="applyFilter" :items="genreOptions" clearable></v-select>
                            </v-col>
                            <v-col cols="12" sm="6" md="4" lg="2">
                                <v-select rounded solo v-model="difficultySearch" label="Difficoltà" @input="applyFilter" :items="difficultyOptions" clearable></v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-data-table :headers="headers" :items="items" :options.sync="pagination" :server-items-length="count" :loading="tableLoading"
                                              no-data-text="Non ci sono pacchetti censiti al momento."  :footer-props="footerProps">

                                    <template v-slot:item.icon.url="{ item }">
                                       <v-img :src="item.icon ? item.icon.url : ''" width="50" height="50" contain style="background: #eee" />
                                    </template>
                                    <template v-slot:item.title.it="{ item }">
                                        <a :href="'./#/kits/'+item._id" ><b>{{ item.title.it }}</b></a>
                                    </template>
                                    <template v-slot:item.category.name.it="{ item }">
                                        <a :href="'./#/categories/'+item.category._id" >{{ item.category.name.it }}</a>
                                    </template>
                                    <template v-slot:item.genre.name.it="{ item }">
                                        <a :href="'./#/genre/'+item.genre._id" >{{ item.genre.name.it }}</a>
                                    </template>
                                    <template v-slot:item.difficulty.name.it="{ item }">
                                        <a :href="'./#/difficulties/'+item.difficulty._id" >{{ item.difficulty.name.it }}</a>
                                    </template>

                                    <template v-slot:item.pub="{ item }">
                                        <v-icon color="success" v-if="item.pub">mdi-check</v-icon>
                                        <v-icon color="error" v-else>mdi-close</v-icon>
                                    </template>
                                    <template v-slot:item.products="{ item }">
                                        <v-btn color="accent" dark text icon v-on:click="$router.push({ name : 'kit products', params : { id : item._id }})">
                                            <v-icon dark>mdi-cart</v-icon>
                                        </v-btn>
                                    </template>
                                    <template v-slot:item.edit="{ item }">
                                        <v-btn color="accent" dark text icon v-on:click="$router.push({ name : 'kit form', params : { id : item._id }})">
                                            <v-icon dark>mdi-pencil</v-icon>
                                        </v-btn>
                                    </template>
                                    <template v-slot:item.remove="{ item }">
                                        <v-btn color="error" dark text icon v-on:click="requestDelete(item._id)">
                                            <v-icon dark>mdi-close</v-icon>
                                        </v-btn>
                                    </template>
                                    <template v-slot:item.clone="{ item }">
                                        <v-btn color="accent" dark text icon v-on:click="requestClone(item._id)">
                                            <v-icon dark>mdi-content-copy</v-icon>
                                        </v-btn>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>

            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import config from "../config";
    import utils from '../utils'
    export default {
        name: "KitList",
        data () {
            return {
                footerProps : {
                    "items-per-page-text" : "N° risultati per pagina",
                    "items-per-page-options" : [10,20,50,100],
                    "items-per-page" : 10
                },
                titleSearch : null,
                categorySearch : null,
                genreSearch : null,
                difficultySearch : null,
                tableLoading : false,
                count : 0,
                items : [],
                headers : [
                    { text : 'Ordinamento', sortable: true, align: 'left', value : 'order' },
                    { text : 'Icona', sortable: false, align: 'left', value : 'icon.url' },
                    { text : 'Titolo', sortable: true, align: 'left', value : 'title.it' },
                    { text : 'Categoria', sortable: true, align: 'left', value : 'category.name.it' },
                    { text : 'Genere', sortable: true, align: 'left', value : 'genre.name.it' },
                    { text : 'Difficoltà', sortable: true, align: 'left', value : 'difficulty.name.it' },
                    { text : 'Pubblicato', sortable: true, align: 'center', value : 'pub' },
                    { text : 'Prodotti', sortable: false, align: 'center', value : 'products', width : 60 },
                    { text : 'Clona', sortable: false, align: 'center', value : 'clone', width : 60 },
                    { text : 'Modifica', sortable: false, align: 'center', value : 'edit', width : 60 },
                    { text : 'Rimuovi', sortable: false, align: 'center', value : 'remove', width : 60 },
                ],
                pagination: {},
                query : {}
            }
        },
        watch: {
            pagination: {
                handler () {
                    this.refresh();
                },
                deep: true
            }
        },
        computed : {
            ...mapGetters(['profile','categories','genre','difficulties']),
            categoryOptions() {
                return this.categories.map((item)=>{
                    return { text : item.name.it, value: item._id }
                })
            },
            genreOptions() {
                return this.genre.map((item)=>{
                    return { text : item.name.it, value: item._id }
                })
            },
            difficultyOptions() {
                return this.difficulties.map((item)=>{
                    return { text : item.name.it, value: item._id }
                })
            }
        },
        methods : {
            ...mapActions(['sendSuccess', 'sendError', 'requestConfirm', 'logout', 'load', 'get', 'delete','refreshCollection']),
            applyFilter() {
                this.query = {};
                this.query.title = this.titleSearch ? this.titleSearch : undefined;
                this.query.genre = this.genreSearch ? this.genreSearch : undefined;
                this.query.ctg = this.categorySearch ? this.categorySearch : undefined;
                this.query.diff = this.difficultySearch ? this.difficultySearch : undefined;
                this.refresh();
            },
            requestClone( id ) {
                this.requestConfirm({ title : 'Richiesta clonazione', message : 'Sei sicuro di voler duplicare questo elemento?', callback: ( accepted )=>{
                        if(accepted) {
                            this.get({ collection : 'kits', id : id + "/duplicate" }).then(()=>{
                                this.refresh();
                                this.sendSuccess({message:'Elemento duplicato!'});
                            }).catch((err)=>{
                                let message = err.message ? err.message : "errore alla duplicazione dell'elemento";
                                this.sendError({ message });
                                if(config.debug) console.error("Errore di duplicazione dell'elemento",err);
                                if(err.statusCode === 401) {
                                    this.logout();
                                }
                            })

                        }
                    }});
            },
            requestDelete( id ) {
                this.requestConfirm({ title : 'Richiesta cancellazione', message : 'Sei sicuro di voler eliminare definitivamente questo pacchetto? Eliminando un pacchetto elimini in cascata anche tutti i prodotti e tutte le lezioni al suo interno.', callback: ( accepted )=>{
                        if(accepted) {
                            this.delete({ collection : 'kits', id }).then(()=>{
                                this.refresh();
                                this.sendSuccess({message:'Pacchetto eliminato!'});
                            }).catch((err)=>{
                                let message = err.message ? err.message : 'errore alla cancellazione del pacchetto';
                                this.sendError({ message });
                                if(config.debug) console.error("Errore di cancellazione del pacchetto",err);
                                if(err.statusCode === 401) {
                                    this.logout();
                                }
                            })

                        }
                    }});
            },
            refresh() {
                this.tableLoading = true;
                const { sortBy, sortDesc, page, itemsPerPage } = this.pagination;
                let sort = null;
                let order = null;
                if(sortBy.length) {
                    sort = sortBy[0];
                    order = 'ASC';
                    if(sortDesc[0]) order = 'DESC';
                }

                let filter = {
                    query : this.query,
                    limit : itemsPerPage,
                    page, sort, order
                };

                this.load({ collection : 'kits', filter}).then((reply)=>{
                    this.tableLoading = false;
                    if(reply.data) {
                        this.count = reply.count;
                        this.items = reply.data;
                    }
                }).catch((err)=>{
                    this.tableLoading = false;
                    this.sendError({message:"Errore al caricamento dei pacchetti."});
                    if(config.debug) console.error("Errore al caricamento dei pacchetti: " + err);
                    if(err.statusCode === 401) {
                        this.logout();
                    }
                })
            }
        },
        mounted() {
            this.refreshCollection({collection : 'categories'});
            this.refreshCollection({collection : 'genre'});
            this.refreshCollection({collection : 'difficulties'});
            this.refresh();
        }
    }
</script>

<style scoped>

</style>