import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import it from 'vuetify/lib/locale/it'
Vue.use(Vuetify);

export default new Vuetify({
  lang : {
    locales: { it },
    current : 'it'
  },
  theme: {
    themes: {
      light: {
        primary: '#f39530',
        secondary: '#000000',
        accent: '#f39530',
        error: '#d43a2d',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#f7d137'
      },
    },
  },
});
