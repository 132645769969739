import Vue from 'vue'
import VueRouter from 'vue-router'
import KitList from "../pages/KitList";
import UserList from "../pages/UserList";
import Dashboard from "../pages/Dashboard";
import UserForm from "../pages/UserForm";
import GenreList from "../pages/GenreList";
import GenreForm from "../pages/GenreForm";
import DifficultyList from "../pages/DifficultyList";
import DifficultyForm from "../pages/DifficultyForm";
import CategoryList from "../pages/CategoryList";
import CategoryForm from "../pages/CategoryForm";
import MediaList from "../pages/MediaList";
import MediaForm from "../pages/MediaForm";
import ExerciseList from "../pages/ExerciseList";
import ExerciseForm from "../pages/ExerciseForm";
import CardList from "../pages/CardList";
import CardView from "../pages/CardView";
import KitForm from "../pages/KitForm";
import ProductList from "../pages/ProductList";
import ProductForm from "../pages/ProductForm";
import LessonList from "../pages/LessonList";
import LessonForm from "../pages/LessonForm";
import PurchaseList from "../pages/PurchaseList";
import PurchaseForm from "../pages/PurchaseForm";
import PurchaseView from "../pages/PurchaseView";
import HomeList from "../pages/HomeList";
import HomeForm from "../pages/HomeForm";
import ReportList from "../pages/ReportList";
import ReportView from "../pages/ReportView";
import SettingsForm from "../pages/SettingsForm";
import Calendar from "../pages/Calendar";
import PacketList from "../pages/PacketList";
import PacketForm from "../pages/PacketForm";
import PromoList from "../pages/PromoList";
import PromoForm from "../pages/PromoForm";
import CmsList from "../pages/CmsList";
import CmsForm from "../pages/CmsForm";
import OpenGym from "../pages/OpenGym";
import TopGymExperience from "../pages/TopGymExperience";
import SalaCorsi from "../pages/SalaCorsi";
import BookingPrefsList from "../pages/BookingPrefsList";
import BookingPrefForm from "../pages/BookingPrefForm";
import Vacu from "../pages/Vacu";
import SubcategoryList from "../pages/SubcategoryList";
import SubcategoryForm from "../pages/SubcategoryForm";
import Gym from "../pages/Gym";
import BannerList from "../pages/BannerList";
import BannerForm from "../pages/BannerForm";
import GalleryList from "../pages/GalleryList";
import GalleryForm from "../pages/GalleryForm";
import UserSeats from "../pages/UserSeats.vue";
import VisiteMediche from "../pages/VisiteMediche.vue";
import Medical from "../pages/Medical.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'dashboard',
    component: Dashboard
  },
  {
    path: '/cards',
    name: 'cards',
    component: CardList
  },
  {
    path: '/cards/:id',
    name: 'card view',
    component: CardView
  },
  {
    path: '/kits',
    name: 'kits',
    component: KitList
  },
  {
    path: '/kits/:id',
    name: 'kit form',
    component: KitForm
  },
  {
    path: '/kits/:id/products',
    name: 'kit products',
    component: ProductList
  },
  {
    path: '/kits/:kitId/products/:id',
    name: 'product form',
    component: ProductForm
  },
  {
    path: '/kits/:kitId/products/:id/lessons',
    name: 'product lessons',
    component: LessonList
  },
  {
    path: '/kits/:kitId/products/:productId/lessons/:id',
    name: 'lesson form',
    component: LessonForm
  },
  {
    path: '/users',
    name: 'users',
    component: UserList
  },
  {
    path: '/users/:id',
    name: 'user form',
    component: UserForm
  },
  {
    path: '/users/:id/seats',
    name: 'user seats',
    component: UserSeats
  },
  {
    path: '/genre',
    name: 'genre',
    component: GenreList
  },
  {
    path: '/genre/:id',
    name: 'genre form',
    component: GenreForm
  },
  {
    path: '/difficulties',
    name: 'difficulties',
    component: DifficultyList
  },
  {
    path: '/difficulties/:id',
    name: 'difficulty form',
    component: DifficultyForm
  },
  {
    path: '/subcategories',
    name: 'subcategories',
    component: SubcategoryList
  },
  {
    path: '/subcategories/:id',
    name: 'subcategory form',
    component: SubcategoryForm
  },
  {
    path: '/categories',
    name: 'categories',
    component: CategoryList
  },
  {
    path: '/categories/:id',
    name: 'category form',
    component: CategoryForm
  },{
    path: '/cms',
    name: 'cms',
    component: CmsList
  },
  {
    path: '/cms/:id',
    name: 'cms form',
    component: CmsForm
  },{
    path: '/banners',
    name: 'banners',
    component: BannerList
  },
  {
    path: '/banners/:id',
    name: 'banner form',
    component: BannerForm
  },{
    path: '/gallery',
    name: 'gallery',
    component: GalleryList
  },
  {
    path: '/gallery/:id',
    name: 'gallery form',
    component: GalleryForm
  },
  {
    path: '/media',
    name: 'media',
    component: MediaList
  },
  {
    path: '/media/:id',
    name: 'media form',
    component: MediaForm
  },
  {
    path: '/exercises',
    name: 'exercises',
    component: ExerciseList
  },
  {
    path: '/exercises/:id',
    name: 'exercise form',
    component: ExerciseForm
  },
  {
    path: '/sells',
    name: 'sells',
    component: PurchaseList
  },
  {
    path: '/sells/:id',
    name: 'sell form',
    component: PurchaseForm
  },
  {
    path: '/sells/:id/view',
    name: 'sell view',
    component: PurchaseView
  },
  {
    path: '/home',
    name: 'home',
    component: HomeList
  },
  {
    path: '/home/:id',
    name: 'home form',
    component: HomeForm
  },
  {
    path: '/reports',
    name: 'reports',
    component: ReportList
  },
  {
    path: '/reports/:id',
    name: 'report view',
    component: ReportView
  },
  {
    path: '/settings',
    name: 'settings',
    component: SettingsForm
  },
  {
    path: '/calendar',
    name: 'calendar',
    component: Calendar
  },
  {
    path: '/topgymexperience',
    name: 'topgymexperience',
    component: TopGymExperience
  },
  {
    path: '/salacorsi',
    name: 'salacorsi',
    component: SalaCorsi
  },
  {
    path: '/opengym',
    name: 'opengym',
    component: OpenGym
  },
  {
    path: '/gym',
    name: 'gym',
    component: Gym
  },
  {
    path: '/vacu',
    name: 'vacu',
    component: Vacu
  },
  {
    path: '/medical',
    name: 'medical',
    component: Medical
  },
  {
    path: '/packets',
    name: 'packets',
    component: PacketList
  },
  {
    path: '/packets/:id',
    name: 'packet form',
    component: PacketForm
  },
  {
    path: '/promo',
    name: 'promo',
    component: PromoList
  },
  {
    path: '/promo/:id',
    name: 'promo form',
    component: PromoForm
  },
    /*
  {
    path: '/bookings',
    name: 'bookings',
    component: Bookings
  },*/
  {
    path: '/bookingprefs',
    name: 'bookingprefs',
    component: BookingPrefsList
  },
  {
    path: '/bookingprefs/:id',
    name: 'bookingpref form',
    component: BookingPrefForm
  },
  {
    path: '/mediche',
    name: 'visitemediche',
    component: VisiteMediche
  },
];

const router = new VueRouter({
  routes
});

export default router
