<template>
  <v-container fluid class="white fill-height d-flex justify-center align-start" >

    <v-row v-if="is_editor">
      <v-col cols="12">
        <h2>Accesso veloce</h2>
        <v-divider />
      </v-col>
      <v-col cols="6" sm="4" md="3" lg="2">
        <v-card class="orange lighten-3" @click="$router.push('/kits')">
          <v-card-text>
            <h2 class="display-1 d-inline-flex align-center"><v-icon size="32" class="mr-2">mdi-dumbbell</v-icon>{{dashboard.num_kits}}</h2>
            <p class="subtitle-2 font-weight-bold text-uppercase mb-0">pacchetti</p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6" sm="4" md="3" lg="2">
        <v-card class="green lighten-3" @click="$router.push('/exercises')">
          <v-card-text>
            <h2 class="display-1 d-inline-flex align-center"><v-icon size="32" class="mr-2">mdi-run-fast</v-icon>{{dashboard.num_exercises}}</h2>
            <p class="subtitle-2 font-weight-bold text-uppercase mb-0">esercizi</p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6" sm="4" md="3" lg="2">
        <v-card class="yellow lighten-3" @click="$router.push('/categories')">
          <v-card-text>
            <h2 class="display-1 d-inline-flex align-center"><v-icon size="32" class="mr-2">mdi-tag</v-icon>{{dashboard.num_categories}}</h2>
            <p class="subtitle-2 font-weight-bold text-uppercase mb-0">categorie</p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6" sm="4" md="3" lg="2">
        <v-card class="green lighten-6" @click="$router.push('/subcategories')">
          <v-card-text>
            <h2 class="display-1 d-inline-flex align-center"><v-icon size="32" class="mr-2">mdi-tag</v-icon>{{dashboard.num_subcategories}}</h2>
            <p class="subtitle-2 font-weight-bold text-uppercase mb-0">sottocategorie prodotto</p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6" sm="4" md="3" lg="2">
        <v-card class="red lighten-3" @click="$router.push('/difficulties')">
          <v-card-text>
            <h2 class="display-1 d-inline-flex align-center"><v-icon size="32" class="mr-2">mdi-alert</v-icon>{{dashboard.num_difficulties}}</h2>
            <p class="subtitle-2 font-weight-bold text-uppercase mb-0">difficoltà</p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6" sm="4" md="3" lg="2">
        <v-card class="purple lighten-3" @click="$router.push('/genre')">
          <v-card-text>
            <h2 class="display-1 d-inline-flex align-center"><v-icon size="32" class="mr-2">mdi-gender-male-female</v-icon>{{dashboard.num_genre}}</h2>
            <p class="subtitle-2 font-weight-bold text-uppercase mb-0">generi</p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6" sm="4" md="3" lg="2">
        <v-card class="cyan lighten-3" @click="$router.push('/media')">
          <v-card-text>
            <h2 class="display-1 d-inline-flex align-center"><v-icon size="32" class="mr-2">mdi-play-network</v-icon>{{dashboard.num_media}}</h2>
            <p class="subtitle-2 font-weight-bold text-uppercase mb-0">file media</p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6" sm="4" md="3" lg="2">
        <v-card class="lime lighten-3" @click="$router.push('/sells')">
          <v-card-text>
            <h2 class="display-1 d-inline-flex align-center"><v-icon size="32" class="mr-2">mdi-cart</v-icon>{{dashboard.num_sells}}</h2>
            <p class="subtitle-2 font-weight-bold text-uppercase mb-0">vendite</p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6" sm="4" md="3" lg="2">
        <v-card class="blue lighten-3" @click="$router.push('/cards')">
          <v-card-text>
            <h2 class="display-1 d-inline-flex align-center"><v-icon size="32" class="mr-2">mdi-card-account-details</v-icon>{{dashboard.num_cards}}</h2>
            <p class="subtitle-2 font-weight-bold text-uppercase mb-0">tesserati MSP</p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6" sm="4" md="3" lg="2">
        <v-card class="blue-grey lighten-3" @click="$router.push('/users')">
          <v-card-text>
            <h2 class="display-1 d-inline-flex align-center"><v-icon size="32" class="mr-2">mdi-account</v-icon>{{dashboard.num_users}}</h2>
            <p class="subtitle-2 font-weight-bold text-uppercase mb-0">utenti</p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6" sm="4" md="3" lg="2">
        <v-card class="deep-orange lighten-3" @click="$router.push('/home')">
          <v-card-text>
            <h2 class="display-1 d-inline-flex align-center"><v-icon size="32" class="mr-2">mdi-tablet-dashboard</v-icon>{{dashboard.home_sections}}</h2>
            <p class="subtitle-2 font-weight-bold text-uppercase mb-0">sezioni homefitness</p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6" sm="4" md="3" lg="2">
        <v-card class="pink lighten-3" @click="$router.push('/reports')">
          <v-card-text>
            <h2 class="display-1 d-inline-flex align-center"><v-icon size="32" class="mr-2">mdi-format-list-checks</v-icon>{{dashboard.num_reports}}</h2>
            <p class="subtitle-2 font-weight-bold text-uppercase mb-0">report utenti</p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <h2>Riepilogo prenotazioni</h2>
        <v-divider />
      </v-col>
      <v-col cols="6" md="4" lg="3">
        <v-card class="white" @click="$router.push('/topgymexperience')">
          <v-card-text>
            <h2 class="title d-inline-flex align-center">TOP GYM EXPERIENCE</h2>
            <p class="subtitle-2 text-uppercase mb-0">OGGI <strong>{{dashboard.today_bookings_exp}}</strong></p>
            <p class="subtitle-2 text-uppercase mb-0">DOMANI <strong>{{dashboard.tomorrow_bookings_exp}}</strong></p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6" md="4" lg="3">
        <v-card class="white" @click="$router.push('/salacorsi')">
          <v-card-text>
            <h2 class="title d-inline-flex align-center">SALA CORSI</h2>
            <p class="subtitle-2 text-uppercase mb-0">OGGI <strong>{{dashboard.today_bookings_corsi}}</strong></p>
            <p class="subtitle-2 text-uppercase mb-0">DOMANI <strong>{{dashboard.tomorrow_bookings_corsi}}</strong></p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6" md="4" lg="3">
        <v-card class="white" @click="$router.push('/opengym')">
          <v-card-text>
            <h2 class="title d-inline-flex align-center">OPEN GYM</h2>
            <p class="subtitle-2 text-uppercase mb-0">OGGI <strong>{{dashboard.today_bookings_opengym}}</strong></p>
            <p class="subtitle-2 text-uppercase mb-0">DOMANI <strong>{{dashboard.tomorrow_bookings_opengym}}</strong></p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6" md="4" lg="3">
        <v-card class="white" @click="$router.push('/vacu')">
          <v-card-text>
            <h2 class="title d-inline-flex align-center">VACU</h2>
            <p class="subtitle-2 text-uppercase mb-0">OGGI <strong>{{dashboard.today_bookings_vacu}}</strong></p>
            <p class="subtitle-2 text-uppercase mb-0">DOMANI <strong>{{dashboard.tomorrow_bookings_vacu}}</strong></p>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="6" md="4" lg="3">
        <v-card class="white" @click="$router.push('/gym')">
          <v-card-text>
            <h2 class="title d-inline-flex align-center">BIKE INDOOR</h2>
            <p class="subtitle-2 text-uppercase mb-0">OGGI <strong>{{dashboard.today_bookings_gym}}</strong></p>
            <p class="subtitle-2 text-uppercase mb-0">DOMANI <strong>{{dashboard.tomorrow_bookings_gym}}</strong></p>
          </v-card-text>
        </v-card>
      </v-col>

    </v-row>
    <v-row v-if="is_admin">
      <v-col cols="12">
        <h2>Abbonamenti attivi</h2>
        <v-divider />
      </v-col>

      <v-col cols="6" md="4" v-for="k in activeKits" :key="k._id">
        <v-card class="white" @click="openSubDetail(k.title.it.toUpperCase(), getActiveByKit(k))">
          <v-card-text>
            <h2 class="title d-inline-flex align-center">{{k.title.it.toUpperCase()}} ATTIVI</h2>
            <p class="subtitle-2 text-uppercase mb-0"><strong>{{getActiveByKit(k).length}}</strong> ad oggi</p>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="6" md="4">
        <v-card class="white"  @click="openSubDetail('MAI UTILIZZATI', unusedSeats)">
          <v-card-text>
            <h2 class="title d-inline-flex align-center">PACCHETTI A SCADENZA MAI UTILIZZATI</h2>
            <p class="subtitle-2 text-uppercase mb-0"><strong>{{unusedSeats.length}} PACCHETTI</strong></p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <h2>Andamento vendite</h2>
        <v-divider />
      </v-col>
      <v-col cols="12" md="6">
        <h4>N° pacchetti sedute venduti ultimi 7 giorni</h4>
        <v-sheet color="accent">
          <v-sparkline
              :value="dashboard.num_sells_seats_7_days"
              color="rgba(255, 255, 255, .7)"
              height="100"
              padding="24"
              stroke-linecap="round"
              smooth
          >
            <template v-slot:label="item">
              {{ item.value }}
            </template>
          </v-sparkline>
        </v-sheet>
      </v-col>
      <v-col cols="12" md="6">
        <h4>Ricavi pacchetti sedute ultimi 7 giorni</h4>
        <v-sheet color="accent">
          <v-sparkline
              :value="dashboard.sells_seats_7_days"
              color="rgba(255, 255, 255, .7)" height="100" padding="24" stroke-linecap="round" smooth>
            <template v-slot:label="item">
              {{ item.value }}€
            </template>
          </v-sparkline>
        </v-sheet>
      </v-col>
      <v-col cols="12" md="6">
        <h4>N° pacchetti home fitness venduti ultimi 7 giorni</h4>
        <v-sheet color="accent">
          <v-sparkline
              :value="dashboard.num_sells_fit_7_days"
              color="rgba(255, 255, 255, .7)" height="100" padding="24"
              stroke-linecap="round" smooth
          >
            <template v-slot:label="item">
              {{ item.value }}
            </template>
          </v-sparkline>
        </v-sheet>
      </v-col>
      <v-col cols="12" md="6">
        <h4>Ricavi pacchetti home fitness ultimi 7 giorni</h4>
        <v-sheet color="accent">
          <v-sparkline
              :value="dashboard.sells_fit_7_days"
              color="rgba(255, 255, 255, .7)"
              height="100" padding="24" stroke-linecap="round" smooth>
            <template v-slot:label="item">
              {{ item.value }}€
            </template>
          </v-sparkline>
        </v-sheet>
      </v-col>
      <v-col cols="12" md="6">
        <h4>Totale pacchetti venduti ultimi 7 giorni</h4>
        <v-sheet color="accent">
          <v-sparkline
              :value="dashboard.num_sells_7_days"
              color="rgba(255, 255, 255, .7)" height="100" padding="24"
              stroke-linecap="round" smooth
          >
            <template v-slot:label="item">
              {{ item.value }}
            </template>
          </v-sparkline>
        </v-sheet>
      </v-col>
      <v-col cols="12" md="6">
        <h4>Ricavi totali ultimi 7 giorni</h4>
        <v-sheet color="accent">
          <v-sparkline
              :value="dashboard.sells_7_days"
              color="rgba(255, 255, 255, .7)"
              height="100" padding="24" stroke-linecap="round" smooth>
            <template v-slot:label="item">
              {{ item.value }}€
            </template>
          </v-sparkline>
        </v-sheet>
      </v-col>




      <v-col cols="6" md="4" lg="3">
        <v-card class="white" @click="$router.push('/sells')">
          <v-card-text>
            <h2 class="title d-inline-flex align-center">PACCHETTI SEDUTE</h2>
            <p class="subtitle-2 text-uppercase mb-0"><strong>{{dashboard.num_sells_seats}}</strong> venduti</p>
            <p class="subtitle-2 text-uppercase mb-0"><strong>{{dashboard.sells_seats.toFixed(2)}}€</strong> totali</p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6" md="4" lg="3">
        <v-card class="white" @click="$router.push('/sells')">
          <v-card-text>
            <h2 class="title d-inline-flex align-center">PACCHETTI HOME FIT</h2>
            <p class="subtitle-2 text-uppercase mb-0"><strong>{{dashboard.num_sells_fit}}</strong> venduti</p>
            <p class="subtitle-2 text-uppercase mb-0"><strong>{{dashboard.sells_fit.toFixed(2)}}€</strong> totali</p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6" md="4" lg="3">
        <v-card class="white" @click="$router.push('/sells')">
          <v-card-text>
            <h2 class="title d-inline-flex align-center">TOTALE</h2>
            <p class="subtitle-2 text-uppercase mb-0"><strong>{{dashboard.num_sells}}</strong> venduti</p>
            <p class="subtitle-2 text-uppercase mb-0"><strong>{{dashboard.sells.toFixed(2)}}€</strong> totali</p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="detailDialog" max-width="1600" scrollable>
      <v-card>
        <v-card-title>{{detailDialogTitle}}</v-card-title>
        <v-card-text>
          <v-text-field v-model="detailDialogQ" label="Cerca per utente" clearable solo rounded hide-details style="max-width: 500px"></v-text-field>
          <v-simple-table>
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-left">
                  Utente
                </th>
                <th class="text-left">
                  Sedute nel pacchetto
                </th>
                <th class="text-left">
                  Disponibilità
                </th>
                <th class="text-left">
                  Prodotto
                </th>
                <th class="text-left">
                  Data Acquisto
                </th>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="item in detailDialogItemsFilter"
                  :key="item._id"
              >
                <td><a class="text-decoration-underline text--primary font-weight-bold" @click="$router.push('/users/'+item.user_id)">{{item.user_name}}</a></td>
                <td>
                  <p class="mb-0">Tipologia sedute: <v-chip-group v-if="item.seats_types">
                    <v-chip small v-for="(st,j) in item.seats_types" :key="`${item._id}_seats_${j}`">{{getSeatsName(st)}}</v-chip>
                  </v-chip-group></p>

                </td>
                <td>
                  <p v-if="item.consumable" class="mb-0">N° sedute disponibili: {{item.available}}</p>
                  <p v-if="item.consumable" class="mb-0">N° sedute consumate: {{item.used}}</p>
                  <p class="mb-1">Durata: {{item.duration}} giorni</p>
                  <p class="mb-1" v-if="item.expiration">Scadenza: {{formatDate(item.expiration)}}</p>
                </td>
                <td>
                  <a v-if="item.product_id" class="text-decoration-underline text--primary font-weight-bold" @click="$router.push('/products/'+item.product_id)">{{getProductName(item.product_id)}}</a>
                  <a v-else-if="item.purchase_id && getProduct(item.purchase_id)" class="text-decoration-underline text--primary font-weight-bold" @click="$router.push('/products/'+getProduct(item.purchase_id)._id)">{{getProduct(item.purchase_id).title.it}}</a>
                  <a v-else-if="item.purchase_id && !getProduct(item.purchase_id)" class="text-decoration-underline text--primary font-weight-bold">{{getPurchasegetPurchase(item.purchase_id).product_id}} (cancellato)</a>
                  <span v-else>-</span>
                </td>
                <td>
                  <a v-if="item.purchase_id" class="text-decoration-underline text--primary font-weight-bold" @click="$router.push('/purchases/'+item.purchase_id)">{{ getPurchaseDate(item.purchase_id) }}</a>
                  <span v-else>-</span>
                </td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="detailDialog = false;">Chiudi</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import config from "../config";
import utils from "../utils";
import {productType, seatsTypes} from "../consts";
import {DateTime} from "luxon";

export default {
  name: "Dashboard",
  data () {
    return {
      detailDialog : false,
      detailDialogTitle : 'Lista abbonamenti attivi',
      detailDialogItems : [],
      detailDialogQ : '',
      validseats : [],
      dashboard : {
        num_users : 0,
        num_kits : 0,
        num_exercises : 0,
        num_categories : 0,
        num_subcategories : 0,
        num_genre : 0,
        num_difficulties : 0,
        num_media : 0,
        num_cards : 0,
        num_reports : 0,
        today_bookings_exp : 0,
        tomorrow_bookings_exp : 0,
        today_bookings_corsi : 0,
        tomorrow_bookings_corsi : 0,
        today_bookings_vacu : 0,
        tomorrow_bookings_vacu : 0,
        today_bookings_gym : 0,
        tomorrow_bookings_gym : 0,
        today_bookings_opengym : 0,
        tomorrow_bookings_opengym : 0,
        num_sells_seats_7_days : [],
        num_sells_fit_7_days : [],
        num_sells_7_days : [],
        sells_seats_7_days : [],
        sells_fit_7_days : [],
        sells_7_days : [],
        num_sells_seats : 0,
        num_sells_fit : 0,
        num_sells : 0,
        sells_seats : 0,
        sells_fit : 0,
        sells : 0,
        this_month_exp_subscription : 0,
        this_month_vacu_subscription : 0,
        this_month_gym_subscription : 0,
        this_month_corsi_subscription : 0,
        this_month_open_gym_subscription : 0,
        num_unused_subscription : 0,

        exp_seats : [],
        vacu_seats : [],
        corsi_seats : [],
        open_gym_seats : [],
        gym_seats : [],
        unused_seats : [],
      },
    }
  },
  computed : {
    ...mapGetters(['is_editor','is_admin','users','kits','products','purchases','userseats']),
    detailDialogItemsFilter() {
      console.log("detailDialogItemsFilter", this.detailDialogQ)
      return this.detailDialogItems.map(i => {
        i.user_name = this.getUser(i.user_id)
        return i
      }).filter(i => {
        if(!this.detailDialogQ) return true
        return i.user_name.search(new RegExp(this.detailDialogQ, "i")) > -1
      })
    },
    activeKits() {
      return this.kits.filter(k => k.category && k.category._id === '60940ed27398889e2bebb889' && k.pub === true)
    },
    unusedSeats() {
      return this.validseats.filter(us => !us.expiration)
    },
  },
  methods : {
    ...mapActions(['sendSuccess', 'sendError', 'requestConfirm', 'logout', 'load', 'get','refreshCollection','refreshCollectionAsync']),
    loadDashboard() {
      let filter = {
        query : {},
        limit : 0,
        page : 0,
        sort : null,
        order : null
      };
      this.load({ collection : 'dashboard', filter}).then((reply)=>{
        if(reply.data) {
          this.dashboard = reply.data;

        }
      }).catch((err)=>{
        this.sendError({message:"Errore al caricamento della dashboard."});
        if(config.debug) console.error("Errore al caricamento della dashboardi: " + err);
        if(err.statusCode === 401) {
          this.logout();
        }
      })
    },
    openSubDetail( title, items ) {
      this.detailDialogItems = items
      this.detailDialogTitle = `Lista abbonamenti attivi "${title}"`;
      this.detailDialog = true
    },
    getUser(id) {
      const user = utils.getObjectInArray(this.users, '_id', id)
      return user ? `${user.name} ${user.surname}` : `${id} (cancellato)`
    },
    getProductName(id) {
      const product = utils.getObjectInArray(this.products, '_id', id)
      return product ? product.title.it : `${id} (cancellato)`
    },
    getProduct(purchase_id) {
      const pur = utils.getObjectInArray(this.purchases, '_id', purchase_id)
      if(!pur) return null
      return utils.getObjectInArray(this.products, '_id', pur.product_id)
      //return product ? product.title.it : `${pur.product_id} (cancellato)`
    },
    getPurchase(purchase_id) {
      return utils.getObjectInArray(this.purchases, '_id', purchase_id)
    },
    getPurchaseDate(purchase_id) {
      const pur = utils.getObjectInArray(this.purchases, '_id', purchase_id)
      if(!pur) return '-'
      return utils.formatDateTime(pur.date)
    },
    getSeatsName( s ) {
      switch (s) {
        case seatsTypes.topgymexperience:
          return "Top Gym Experience"
        case seatsTypes.opengym:
          return "Open Gym"
        case seatsTypes.corsi:
          return "Sala corsi"
        case seatsTypes.gym:
          return "Bike indoor"
        case seatsTypes.vacu:
          return "Vacu"
      }
    },
    formatDate : utils.formatDate,
    getActiveByKit(k) {
      const products = this.products.filter(p => p.kit && p.kit._id === k._id && p.is_seats_product)
      //console.log("products", products.length)
      let seats = []
      for(let i = 0; i < products.length; i++) {
        const p = products[i]
        const st =  p.seats_types || []
        for(let j = 0; j < st.length; j++) {
          if(!seats.includes(st[j])) {
            seats.push(st[j])
          }
        }
      }
      return this.validseats.filter(us => utils.equalsCheck(us.seats_types, seats))

    }
  },
  async mounted() {


    this.loadDashboard()
    this.refreshCollection({ collection : 'users' })
    this.refreshCollection({ collection : 'kits' })
    this.refreshCollection({ collection : 'products' })
    this.refreshCollection({ collection : 'purchases' })


    await this.refreshCollectionAsync({ collection : 'userseats' })
    this.validseats = this.userseats.filter(us => {
      if(!us.consumable) {
        if(!us.expiration) return true
        const dt = DateTime.fromISO(us.expiration)
        return dt > DateTime.now()
      }
      if(us.expiration) {
        const dt = DateTime.fromISO(us.expiration)
        if(dt < DateTime.now()) return false
      }
      return us.available > us.used
    })
  }
}
</script>

<style scoped>

</style>