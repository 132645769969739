<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <h2 class="display-1 ml-4">Lista prodotti di "{{kit.title.it}}"</h2>
            <v-spacer />
            <v-btn color="accent" text @click="$router.push('/kits/'+kit._id+'/products/new')">Nuovo</v-btn>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-data-table :headers="headers" :items="items" :options.sync="pagination" :server-items-length="count" :loading="tableLoading"
                              no-data-text="Non ci sono prodotti censiti al momento."  :footer-props="footerProps">

                  <template v-slot:item.title.it="{ item }">
                    <a :href="'./#/kits/'+kit._id+'/products/'+item._id" ><b>{{ item.title.it }}</b></a>
                  </template>
                  <template v-slot:item.price="{ item }">
                    {{ item.price.toFixed(2) }}€<span v-if="item.second_price">, riacquisto: {{ item.second_price.toFixed(2) }}€</span>
                  </template>
                  <template v-slot:item.lessons="{ item }">
                    <v-btn :disabled="item.is_seats_product" color="accent" dark text icon v-on:click="$router.push({ name : 'product lessons', params : { kitId : kit._id, id : item._id }})">
                      <v-icon dark>mdi-run</v-icon>
                    </v-btn>
                  </template>
                  <template v-slot:item.type="{ item }">
                    <p class="mb-0">{{getProductTypeLabel(item.type)}}</p>
                    <span v-if="item.type === 'distinct' && item.seats_has_same_expiration">(con scadenza condivisa)</span>
                    <span v-if="item.type === 'distinct' && !item.seats_has_same_expiration">(con scadenze separate)</span>
                  </template>
                  <template v-slot:item.seats="{ item }">
                    <div v-if="item.type === productType.mixed">
                      <p class="mb-0">Tipologia sedute:
                        <v-chip-group v-if="item.seats_types">
                          <v-chip small v-for="(s,i) in item.seats_types" :key="`${item._id}_seats_${i}`">{{getSeatsName(s)}}</v-chip>
                        </v-chip-group>
                      </p>
                      <p v-if="item.consumable" class="mb-0">N° sedute: {{item.num_seats}}</p>
                      <p class="mb-0">Durata: {{item.seats_duration}} giorni</p>
                    </div>
                    <div v-else-if="item.type === productType.distinct" >
                      <div v-for="(s,i) in item.seats" style="border-bottom: 1px solid #ddd">
                        <p class="mb-0">Tipologia sedute: <v-chip-group v-if="s.seats_types">
                          <v-chip small v-for="(st,j) in s.seats_types" :key="`${item._id}_seats_${i}_${j}`">{{getSeatsName(st)}}</v-chip>
                        </v-chip-group></p>
                        <p v-if="s.consumable" class="mb-0">N° sedute: {{s.num_seats}}</p>
                        <p class="mb-1">Durata: {{s.duration}} giorni</p>
                      </div>
                    </div>
                    <span v-else>-</span>
                  </template>


                  <template v-slot:item.edit="{ item }">
                    <v-btn color="accent" dark text icon v-on:click="$router.push({ name : 'product form', params : { kitId : kit._id, id : item._id }})">
                      <v-icon dark>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <template v-slot:item.remove="{ item }">
                    <v-btn color="error" dark text icon v-on:click="requestDelete(item._id)">
                      <v-icon dark>mdi-close</v-icon>
                    </v-btn>
                  </template>
                  <template v-slot:item.clone="{ item }">
                    <v-btn color="accent" dark text icon v-on:click="requestClone(item._id)">
                      <v-icon dark>mdi-content-copy</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import config from "../config";
import moment from 'moment'
import utils from '../utils'
import {productType, productTypeOptions, seatsTypes} from "../consts";
export default {
  name: "ProductList",
  data () {
    return {
      productType,
      productTypeOptions,
      kit : {
        title : {
          it : ''
        }
      },
      footerProps : {
        "items-per-page-text" : "N° risultati per pagina",
        "items-per-page-options" : [10,20,50,100],
        "items-per-page" : 10
      },
      tableLoading : false,
      count : 0,
      items : [],
      headers : [
        { text : 'Titolo', sortable: true, align: 'left', value : 'title.it' },
        { text : 'Codice prodotto (SKU)', sortable: true, align: 'left', value : 'sku' },
        { text : 'Prezzo', sortable: true, align: 'left', value : 'price' },
        { text: 'Tipologia', sortable: false, align: 'left', value: 'type' },
        { text: 'Sedute', sortable: false, align: 'left', value: 'seats' },
        { text: 'Intervallo giorni', sortable: true, align: 'left', value: 'days_interval' },
        { text : 'Lezioni', sortable: false, align: 'center', value : 'lessons', width : 60 },
        { text : 'Clona', sortable: false, align: 'center', value : 'clone', width : 60 },
        { text : 'Modifica', sortable: false, align: 'center', value : 'edit', width : 60 },
        { text : 'Rimuovi', sortable: false, align: 'center', value : 'remove', width : 60 },
      ],
      roleOptions : config.roles,
      pagination: {},
      query : {
        kit : null
      }
    }
  },
  watch: {
    pagination: {
      handler () {
        this.refresh();
      },
      deep: true
    }
  },
  computed : {
    ...mapGetters(['profile'])
  },
  methods : {
    ...mapActions(['sendSuccess', 'sendError', 'requestConfirm', 'logout', 'load', 'delete','get']),
    applyFilter() {
      this.query = {};
      this.refresh();
    },
    getProductTypeLabel(pt) {

      const opt = utils.getObjectInArray(productTypeOptions,'value', pt)
      return opt ? opt.text : opt;
    },
    getSeatsName(s) {
      /*
      <v-chip small v-if="item.is_seats_product && item.seats_types && item.seats_types.includes('topgymexperience')">TopGym Experience</v-chip>
                    <v-chip small v-if="item.is_seats_product && item.seats_types && item.seats_types.includes('opengym')">Open Gym</v-chip>
                    <v-chip small v-if="item.is_seats_product && item.seats_types && item.seats_types.includes('corsi')">Sala Corsi</v-chip>
                    <v-chip small v-if="item.is_seats_product && item.seats_types && item.seats_types.includes('onetoone')">One-to-One</v-chip>
                    <v-chip small v-if="item.is_seats_product && item.seats_types && item.seats_types.includes('gym')">Ginnastica e Corpo Libero</v-chip>
                    <v-chip small v-if="item.is_seats_product && item.seats_types && item.seats_types.includes('vacu')">Vacu</v-chip>
       */
      switch (s) {
        case seatsTypes.topgymexperience:
          return "Top Gym Experience"
        case seatsTypes.opengym:
          return "Open Gym"
        case seatsTypes.corsi:
          return "Sala corsi"
        case seatsTypes.gym:
          return "Bike indoor"
        case seatsTypes.vacu:
          return "Vacu"
      }
      return s
    },
    requestClone( id ) {
      this.requestConfirm({ title : 'Richiesta clonazione', message : 'Sei sicuro di voler duplicare questo elemento?', callback: ( accepted )=>{
          if(accepted) {
            this.get({ collection : 'products', id : id + "/duplicate" }).then(()=>{
              this.refresh();
              this.sendSuccess({message:'Elemento duplicato!'});
            }).catch((err)=>{
              let message = err.message ? err.message : "errore alla duplicazione dell'elemento";
              this.sendError({ message });
              if(config.debug) console.error("Errore di duplicazione dell'elemento",err);
              if(err.statusCode === 401) {
                this.logout();
              }
            })

          }
        }});
    },
    requestDelete( id ) {
      this.requestConfirm({ title : 'Richiesta cancellazione', message : 'Sei sicuro di voler eliminare definitivamente questo prodotto? Eliminando un prodotto elimini in cascata anche tutte le lezioni al suo interno.', callback: ( accepted )=>{
          if(accepted) {
            this.delete({ collection : 'products', id }).then(()=>{
              this.refresh();
              this.sendSuccess({message:'Prodotto eliminato!'});
            }).catch((err)=>{
              let message = err.message ? err.message : 'errore alla cancellazione del prodotto';
              this.sendError({ message });
              if(config.debug) console.error("Errore di cancellazione del prodotto",err);
              if(err.statusCode === 401) {
                this.logout();
              }
            })

          }
        }});
    },
    refresh() {
      this.tableLoading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.pagination;
      let sort = null;
      let order = null;
      if(sortBy.length) {
        sort = sortBy[0];
        order = 'ASC';
        if(sortDesc[0]) order = 'DESC';
      }

      let filter = {
        query : this.query,
        limit : itemsPerPage,
        page, sort, order
      };

      this.load({ collection : 'products', filter}).then((reply)=>{
        this.tableLoading = false;
        if(reply.data) {
          this.count = reply.count;
          this.items = reply.data;
        }
      }).catch((err)=>{
        this.tableLoading = false;
        this.sendError({message:"Errore al caricamento dei prodotti."});
        if(config.debug) console.error("Errore al caricamento dei prodotti: " + err);
        if(err.statusCode === 401) {
          this.logout();
        }
      })
    }
  },
  mounted() {
    this.query.kit = this.$route.params.id;
    this.kit._id = this.$route.params.id;
    this.get({collection:"kits", id: this.$route.params.id}).then((reply) => {
      if(!reply.data) {
        this.$router.go(-1);
        return
      }
      this.kit = reply.data;
    }).catch((err)=>{
      console.log(err);
      this.$router.go(-1);
    });
    this.refresh();
  }
}
</script>

<style scoped>

</style>